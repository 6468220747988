import React from "react";
import { Grid, Avatar, Button, Typography, Box, useMediaQuery, useTheme, Link } from "@mui/material";
import Slider from "react-slick";
import ProcessBlurb from "../components/ProcessBlurb";
import MansoryGrid from "../components/MansoryGrid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as Pana } from "../../assets/pana.svg";
import { ReactComponent as ReactIcon } from "../../assets/react.svg";
import { ReactComponent as NodejsIcon } from "../../assets/nodejs.svg";
import { ReactComponent as LaravelIcon } from "../../assets/laravel.svg";
import { ReactComponent as TypescriptIcon } from "../../assets/typescript.svg";
import { ReactComponent as MongodbIcon } from "../../assets/mongodb.svg";
import { ReactComponent as NextjsIcon } from "../../assets/next.svg";
import { ReactComponent as Discovery } from "../../assets/discovery.svg";
import { ReactComponent as Planning } from "../../assets/planning.svg";
import { ReactComponent as Design } from "../../assets/design.svg";
import { ReactComponent as Launching } from "../../assets/launching.svg";
import { ReactComponent as Testing } from "../../assets/texting.svg";
import { ReactComponent as Development } from "../../assets/development.svg";
import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';
import { Link as RouterLink } from "react-router-dom";


const testimonial = [
  {
    name: "John Alex",
    image: "https://source.unsplash.com/random",
    description:
      "I can't recommend Doshby enough! They took our complex project and turned it into a seamless, user-friendly application. The team was incredibly professional and kept us informed at every stage of development. ",
  },
  {
    name: "Shade Olujide",
    image: "https://source.unsplash.com/random",
    description:
      "Doshby transformed our business operations with their custom software solutions. Their team quickly understood our requirements and delivered a scalable solution that has significantly improved our efficiency.",
  },
  {
    name: "Halima Takwanso",
    image: "https://source.unsplash.com/random",
    description:
      "Working with Doshby has been a game-changer for our startup. Their creative approach to problem-solving and attention to detail made all the difference. They developed a robust, scalable solution that perfectly aligns with our business goals.",
  },
];
const tech = [
  {
    icon: <ReactIcon />,
    text: "React",
  },
  {
    icon: <NodejsIcon />,
    text: "NodeJs",
  },
  {
    icon: <LaravelIcon />,
    text: "laravel",
  },
  {
    icon: <NextjsIcon />,
    text: "Nextjs",
  },
  {
    icon: <TypescriptIcon />,
    text: "Typescript",
  },
  {
    icon: <MongodbIcon />,
    text: "MongoDB",
  },
];
const dProcess = [
  {
    icon: <Discovery />,
    text: "Discovery",
  },
  {
    icon: <Planning />,
    text: "Planning",
  },
  {
    icon: <Design />,
    text: "Design",
  },
  {
    icon: <Development />,
    text: "Development",
  },
  {
    icon: <Testing />,
    text: "Testing",
  },
  {
    icon: <Launching />,
    text: "Launching",
  }
];

function FutureProof() {
  return <>
    <Typography variant="h5" mt={4} sx={{
      textAlign: {
        xs: 'center',
        lg: 'left'
      }
    }}>
      Future Proof Technologies
    </Typography>
    <Grid
      container
      className="elements"
      alignItems="start"

      sx={{
        maxWidth: "100%", margin: "3rem 0", gap: '1rem', justifyContent: {
          xs: 'space-around',
          lg: 'flex-start'
        }
      }}
      spacing={2}
    >
      {tech.map((item, index) => (
        <Grid
          item
          xs={4}
          sm={3}
          md={3}
          key={index}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="5px"
          boxShadow={'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'}
        >
          <Typography
            variant="body1"
            sx={{ textTransform: "uppercase" }}
            fontWeight={"bold"}
          >
            {item.text}
          </Typography>
          <span className="icon">{item.icon}</span>
        </Grid>
      ))}
    </Grid></>
}

const containerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 1,
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function HeroNew() {
  return (
    <motion.section
      className="hero-section-2"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="bg"></div>
      <div className="content">
        <Box sx={{
          width: { xs: '100%', sm: '80%', md: '60%' },
        }} component={motion.div} className="info">
          <Typography component={motion.h2} variants={itemVariants} variant="h2" fontWeight={'600'}>
            Build <mark>
              <Typewriter
                options={{
                  strings: ['Powerful', 'Innovative', 'Scalable', 'Efficient'],
                  autoStart: true,
                  loop: true,
                }}
              />
            </mark> Software Solutions With Ease
          </Typography>
          <motion.div variants={itemVariants}>
            <Typography variant="body1">
              Unlock the full potential of your digital ambitions with Doshby. Contact us today to schedule a consultation and learn how our cutting-edge software solutions can propel your business to new heights.
            </Typography>
          </motion.div>
          <motion.div variants={itemVariants}>
            <Button sx={{
              p: '1rem 2rem',
            }} component={RouterLink} to="/schedule" className="btn-primary" variant="contained">
              Book Now
            </Button>
          </motion.div>

        </Box>
        {/* <div className="use-mask"></div> */}
        <div className="absolute"></div>
      </div>
    </motion.section>
  );
}

const breakpointforprocess = {
  default: 3,
  1100: 3,
  700: 1
};

function Homepage() {
  var settings = {
    dots: true,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <main style={{
      backgroundColor: "#ffffff",
      overflowX: "hidden",
    }}>
      <HeroNew />
      <section className="page-section process__section">
        <Grid
          container
          flexDirection={"row-reverse"}
          className="inner"
          justifyContent={"space-between"}
          sx={{
            gap: {
              xs: "3rem",
              lg: "4rem",
            },
          }}
        >
          <Grid
            item
            xs={12}
            lg={6}
            className="left"
            component={motion.div}
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: "2rem",
                lg: "3rem",
              },
              pt: {
                lg: "5rem",
              },
              maxWidth: {
                lg: "40%",
              },
              justifyContent: {
                xs: "center",
                lg: "flex-start",
              },
              alignItems: {
                xs: "center",
                lg: "flex-start",
              },
            }}
          >
            <Typography
              variant="h4"
              className="pg_title"
              sx={{
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              Our Design and development Process{" "}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              At Doshby, we follow a streamlined, agile approach to deliver top-notch
              software solutions. Starting with an in-depth consultation to understand
              your needs, we move through iterative design, development, and testing
              phases. This ensures flexibility, continuous improvement, and a focus on
              quality, resulting in robust, user-friendly software tailored to drive
              your business forward.
            </Typography>
            <Button
              sx={{
                p: '1rem 2rem',
              }}
              LinkComponent={Link}
              to="http://wa.link/blzxa3"
              className="btn-primary"
              variant="contained"
            >
              Chat With Us
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            className="right"
            component={motion.div}
            initial="hidden"
            animate="visible"
            variants={sectionVariants}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              maxWidth: {
                lg: "50%",
              },
            }}
          >
            <MansoryGrid />
          </Grid>
        </Grid>
      </section>;

      <section className="page-section process_elements_section">
        <div className="inner">
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={12} lg={5.7} >
              <Typography variant="h4" className="pg_title" sx={{
                mb: {
                  xs: '1rem',
                  lg: '2rem'
                },
                textAlign: {
                  xs: 'center',
                  lg: 'left'
                }
              }}>
                Process
              </Typography>
              <Typography variant="body1" sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'left'
                }
              }}>
                At Doshby, our process involves a thorough understanding of your needs, creative and strategic planning, meticulous development, and rigorous testing to ensure we deliver a robust and user-friendly solution.
              </Typography>
              {!isMobile && (
                <FutureProof />
              )}
            </Grid>
            <Grid item className="process_elements" xs={12} lg={5.7} sx={{
              margin: {
                xs: '5rem 0 4rem 0',
                lg: '0'
              }
            }}>
              {dProcess.map((item, index) => (
                <ProcessBlurb key={index} text={item.text} icon={item.icon} />
              ))}
            </Grid>
          </Grid>
          {isMobile && (
            <FutureProof />
          )}
          {/* <div className="the-process">
          </div> */}
        </div>
      </section>
      <section className="page-section tech_section">
        <div className="inner">
        </div>
      </section>
      <section></section>
      <section>
        <div className="page-section testimonial__section">
          <div className="custom-shape-divider-top-1716214474">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
          </div>
          <div className="inner">
            <Typography className="pg_title" align="center" variant="h4">
              What Clients Say
            </Typography>
            <Slider {...settings} className="testimonial-slider">
              {testimonial.map((item, index) => (
                <div className="testimonial_item" key={index}>
                  <Avatar
                    alt="Remy Sharp"
                    src={item.image}
                    sx={{ width: 100, height: 100 }}
                  />
                  <Typography variant="body1" fontWeight={'500'}>{item.name}</Typography>
                  <Typography variant="body1">{item.description}</Typography>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="page-section chance">
        <div className="inner">
          <Typography
            color="primary"
            align="center"
            variant="h3"
            fontWeight={'600'}
            className="pg_title"
          >
            Join The Doshby Experience
          </Typography>
          <Grid sx={{ margin: "2rem auto" }} container>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "1rem",
              }}
            >
              <Pana />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "1rem",
                padding: "1rem",
              }}
            >
              <Typography gutterBottom>
                Our expert team is here to help you achieve your business goals with innovative and reliable software solutions. <br />Don’t wait—click the button below to contact us and start your project today!
              </Typography>
              <Button
                sx={{
                  p: '1rem 2rem',
                }}
                component={RouterLink}
                to="/schedule"
                className="btn-primary"
                variant="contained"
              >
                Schedule A Call
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                color="primary"
                align="right"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  lineHeight: 1.5,
                }}
              >
                "Hiring the best is your
                <br /> most important task"
              </Typography>
              <Typography align="right">Steve Jobs</Typography>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </div>
      </section>
    </main >
  );
}

export default Homepage;
