import { Link, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { userStore } from "../../zustand";
import { isEmpty } from "underscore";

function HeaderMenu({ force, useSec }) {
  const [showMenu, setShowMenu] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = userStore()
  return (
    <>
      {(!isMobile || force) && (
        <div className={`nav ${(showMenu || force) && 'open-menu'}`}>
          <nav>
            <ul >
              <Link color={'inherit'} component={RouterLink} to="/portfolio">
                Portfolio
              </Link>
              <Link color={'inherit'} component={RouterLink} to="/about">
                About Us
              </Link>
              <Link color={'inherit'} target="_blank" component={RouterLink} to="https://blog.doshby.com">
                Blog
              </Link>
              {useSec && <>
                {isEmpty(user) ? <>
                  <Link color={'inherit'} component={RouterLink} to="/login">
                    Login
                  </Link>
                  <Link color={'inherit'} component={RouterLink} to="/register">
                    Register
                  </Link>
                </> : <>
                  <Link color={'inherit'} component={RouterLink} to="/dashboard">
                    Dashboard
                  </Link>
                </>
                }
              </>}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}
export default HeaderMenu;
