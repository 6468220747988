import React from 'react'
import { ReactComponent as SuccessSVG } from '../assets/created.svg';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';

const SuccessMsg = ({ clear, setStep, target, text, label }) => {
    const navigate = useNavigate();

    const handleCreateNew = () => {
        clear();
        setStep(1);
    };

    const handleGoToAllProjects = () => {
        navigate(`/${target}`);
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            padding: {
                xs: '2rem 1rem',
                sm: '2rem',
                md: '3rem',
                lg: '6rem 4rem',
            },
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 1,
            margin: '1rem auto',
            maxWidth: {
                xs: '100%',
                sm: '100%',
            }
        }}>
            <Box sx={{
                width: '150px',
                height: '150px',
            }}>
                <SuccessSVG />
            </Box>
            <Typography variant='h5' fontWeight={600} textAlign={'center'} mb={2}>{text}</Typography>
            <Grid mb={2} container spacing={2} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {/* <Grid item xs={6} textAlign={'right'}>
                    <Button variant='outlined' onClick={handleCreateNew}>Create New</Button>
                </Grid> */}
                <Grid item xs={6} textAlign={'left'}>
                    <Button variant="contained" onClick={handleGoToAllProjects}>{label}</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SuccessMsg