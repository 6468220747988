import React, { useState, useEffect } from 'react';
import {
    Box,
    Skeleton,
    Grid,
    Pagination
} from '@mui/material';
import { makeRequest } from '../../util/AxiosInstance';
import { API_URL } from '../../zustand/const';
import PortfolioViewer from './PortfolioView';
import { isEmpty } from 'underscore';
import { usePortfolioStore } from '../../zustand';
import Placeholder from '../../shared/Placeholder';
import PortfolioItem from './PortfolioItem';
import { useQuery } from '@tanstack/react-query';


const PortfolioList = () => {
    const { portfolios: pfData, currentPage, setCurrentPage, addPortfolio, setTotalItems, setHasMore, totalPages, setTotalPages } = usePortfolioStore();
    const [perPage, setPerPage] = useState(6);
    const [showViewer, setShowViewer] = useState(false);
    const [viewerId, setViewerId] = useState(null);

    const fetchPortfolios = async () => {
        const response = await makeRequest('GET', `${API_URL}/portfolio?page=${currentPage}&per_page=${perPage}`);
        return response.data.data;
    };

    const { data, isLoading, isError, refetch } = useQuery({
        queryKey: ['portfolios', currentPage, perPage],
        queryFn: fetchPortfolios,
        onSuccess: (data) => {
            console.log(data);
            const { data: portfoliosData, total, current_page, last_page } = data;
            let toAdd = portfoliosData.filter(item => !portfolios.some(p => p.id === item.id));
            setTotalPages(last_page);
            setCurrentPage(current_page);
            addPortfolio(toAdd);
            setTotalItems(total);
            setHasMore(current_page < last_page);
        }
    });

    useEffect(() => {
        if (!isLoading) {
            fetchPortfolios();
        }
    }, [currentPage]);

    const portfolios = data?.data ?? [];

    const handlePageChange = (e, page) => {
        setCurrentPage(page);
    };


    return (
        <Box sx={{
            maxWidth: '1200px',
            mx: 'auto'
        }}>
            <Grid container spacing={{ xs: 2, sm: 2, md: 4, lg: 6 }} sx={{
                margin: '1rem 0'
            }}>
                {portfolios && !isLoading && !isEmpty(portfolios) && portfolios.length > 0 && portfolios.map((portfolio, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <PortfolioItem portfolio={portfolio} setViewerId={setViewerId} setShowViewer={setShowViewer} />
                    </Grid>
                ))}
            </Grid>
            {isLoading && <>
                <Skeleton variant="rectangular" height={60} sx={{
                    margin: '1rem 0',
                    borderRadius: '1rem'
                }} />
                <Skeleton variant="rectangular" height={60} sx={{
                    margin: '1rem 0',
                    borderRadius: '1rem'
                }} />
                <Skeleton variant="rectangular" height={60} sx={{
                    margin: '1rem 0',
                    borderRadius: '1rem'
                }} />
            </>}
            {isEmpty(portfolios) && !isLoading && <>
                <Placeholder text={'There are no portfolios to show yet :)'} />
            </>}

            {showViewer && <PortfolioViewer portfolios={portfolios} id={viewerId}
                setId={setViewerId}
                modalOpen={showViewer} setModalOpen={setShowViewer}
            />}
            <Pagination sx={{
                margin: '2rem 0',
                display: 'flex',
                justifyContent: 'center'
            }} count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
    );
};

export default PortfolioList;