import React, { useEffect } from 'react'
import BookingEditor from '../../shared/BookingEditor'
import { Box, Typography } from '@mui/material'
import { userStore } from '../../zustand'
import { useNavigate } from 'react-router-dom'

function Schedule() {
  const { user } = userStore()
  const navigate = useNavigate()
  useEffect(() => {
    if (user === null) {
      navigate('/login?to=schedule')
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        pt: {
          xs: '15vh',
          sm: '14vh',
        },
        pb: "3rem",
        margin: '0 auto',
        maxWidth: {
          xs: '80%',
          sm: '70%',
          md: '500px',
          lg: '500px',
        }
      }}
    >
      <Typography variant='h4' fontWeight={600} textAlign={'center'}>Schedule A Meeting</Typography>
      <BookingEditor />
    </Box>
  )
}

export default Schedule