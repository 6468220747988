import React from 'react';
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQSection = ({ title, questions }) => (
  <Box mb={4}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    {questions.map((q, index) => (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{q.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{q.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
);

const FAQ = () => {
  const sections = [
    {
      title: "About Doshby",
      questions: [
        {
          question: "What services does Doshby offer?",
          answer: "Doshby is a leading software development agency offering a full suite of services including front-end development, back-end development, full-stack development, mobile app development, web application development, and more. Our skilled engineers have expertise across diverse technologies like JavaScript, Vue.js, React.js, Python, Django, PHP, Laravel, Node.js, ASP.NET, and others."
        },
        {
          question: "What industries and business types does Doshby serve?",
          answer: "We work with clients across various industries and business types, from startups and small businesses to large enterprises. Our solutions are tailored to meet the unique requirements of each client, ensuring we deliver customized software that drives growth and success."
        }
      ]
    },
    {
      title: "Our Expertise",
      questions: [
        {
          question: "What programming languages and frameworks does Doshby specialize in?",
          answer: "Our team of seasoned developers is well-versed in a wide range of programming languages and frameworks, including JavaScript (Vue.js, React.js), Python (Django), PHP (Laravel), Node.js, ASP.NET, and many others. We continuously stay updated on the latest technologies to provide cutting-edge solutions."
        },
        {
          question: "Does Doshby offer mobile app development services?",
          answer: "Absolutely. Our mobile app development services cater to both iOS and Android platforms, ensuring your app is optimized for performance, user experience, and compatibility across devices."
        }
      ]
    },
    {
      title: "Our Process",
      questions: [
        {
          question: "What is Doshby's development process?",
          answer: "We follow an agile, iterative development approach that emphasizes close collaboration with our clients. Our process typically involves requirements gathering, wireframing/prototyping, design, development, testing, deployment, and ongoing maintenance and support."
        },
        {
          question: "How does Doshby ensure quality and security?",
          answer: "Quality and security are paramount at Doshby. We adhere to industry best practices, perform rigorous testing, and implement robust security measures to safeguard your applications and data. Our engineers stay up-to-date with the latest security protocols and coding standards."
        }
      ]
    },
    {
      title: "Pricing and Timelines",
      questions: [
        {
          question: "How does Doshby determine project pricing?",
          answer: "Our pricing is based on the scope, complexity, and specific requirements of each project. We provide transparent estimates after thoroughly understanding your needs and goals. Our team works diligently to deliver high-quality solutions within agreed-upon budgets."
        },
        {
          question: "How long does a typical project take?",
          answer: "Project timelines can vary depending on the scope and complexity of the project. During our initial consultation, we'll provide you with a realistic timeline estimate based on our experience and the specific requirements of your project."
        }
      ]
    },
    {
      title: "Getting Started",
      questions: [
        {
          question: "How can I get started with Doshby?",
          answer: "To get started, simply reach out to our team by emailing contact@doshby.com or filling out the contact form on our website. We'll schedule an initial consultation to discuss your project requirements and provide you with a tailored proposal."
        },
        {
          question: "What information should I provide for a project estimate?",
          answer: "To provide an accurate estimate, it's helpful to share details about your project goals, target audience, desired features and functionality, technical requirements, timeline expectations, and any other relevant information. The more details you can provide upfront, the better we can assess your needs."
        }
      ]
    }
  ];

  return (
    <Container className='plain-page-container' maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Frequently Asked Questions
        </Typography>
        <Divider sx={{ mb: 4 }} />
        {sections.map((section, index) => (
          <FAQSection key={index} title={section.title} questions={section.questions} />
        ))}
        <Box mt={4}>
          <Typography variant="body1">
            Have more questions? Feel free to <Link href="mailto:contact@doshby.com">contact us</Link> for further assistance.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default FAQ;