import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper } from '@mui/material';
import { makeRequest } from '../../util/AxiosInstance';
import { Notify } from 'notiflix';
import { LoadingButton } from '@mui/lab';
import DisplayAlerts from '../../shared/DisplayAlerts';
import { handleError, sleep } from '../../util/functions';
import { useStore } from 'zustand';
import { userStore } from '../../zustand';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { logout } = userStore()
    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }
        setError('');
        setIsLoading(true);
        makeRequest('post', '/edit-user', {
            old_password: oldPassword,
            password: newPassword,
            confirm_password: confirmPassword,
        }).then(async (res) => {
            if (res.status === 200) {
                setSuccess('Password changed successfully, logging out...');
                Notify.success('Password changed successfully');
                await sleep(1000);
                logout()
            }
        })
            .catch((error) => {
                let msg = handleError(error);
                setError(msg);
                Notify.failure(msg);
                console.error(error);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Paper sx={{ padding: 3, marginTop: 3 }}>
            <Typography variant="h5" mb={3} gutterBottom>
                Change Password
            </Typography>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <DisplayAlerts success={success} apiErrors={error} />
                <TextField
                    label="Old Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    Change Password
                </LoadingButton>
            </form>
        </Paper>
    );
};

export default ChangePassword;