import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { makeRequest } from '../../util/AxiosInstance';

window.Pusher = Pusher;
const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,

    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                (async () => {
                    var socketId = window.Echo.socketId();
                    makeRequest('POST', '/broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name
                    }, {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    })
                        .then(response => {
                            callback(false, response.data);
                        })
                        .catch(error => {
                            callback(true, error);
                        });
                })()
            }
        };
    },

});
window.Echo = echo;
export default echo;