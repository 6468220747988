import React, { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ReactComponent as LoginSVG } from "../../assets/login.svg";
import { LoadingButton } from "@mui/lab";
import { Notify } from "notiflix";
import AuthWrapper from "../components/AuthWrapper";
import { makeRequest } from "../../util/AxiosInstance";
import { isEmpty } from "underscore";
import DisplayAlerts from "../../shared/DisplayAlerts";
import PasswordField from "../../shared/PasswordField";
import { userStore } from "../../zustand";
import { handleError, sleep } from "../../util/functions";
import { IndexedDBUtility } from "../../util/IndexedDb";
import { IndexDbStoreName, IndexedDbName } from "../../zustand/const";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [apiErrors, setApiErrors] = useState(null);
  const { setUser, setToken } = userStore();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  let to = new URLSearchParams(window.location.search).get('to');


  const handleLogin = (e) => {
    e.preventDefault();
    if (isEmpty(formData.email)) {
      setApiErrors("Please enter a valid email address");
      return false;
    }
    if (isEmpty(formData.password)) {
      setApiErrors("Please enter a password");
      return false;
    }
    setLoading(true);
    makeRequest('post', '/login', {
      email: formData.email,
      password: formData.password,
    })
      .then(async (response) => {
        if (response.status === 200) {
          if (!response.hasOwnProperty('data')) {
            console.log('No data');
            throw new Error("Invalid response"
            );
          }
          if (!response.data.hasOwnProperty('data')) {
            throw new Error("Invalid response"
            );
          }
          const { access_token, user } = response.data.data
          if (!access_token || !user) {
            throw new Error("Inappropriate login")
          }
          let db = new IndexedDBUtility(IndexedDbName, IndexDbStoreName);
          await db.openDatabase();
          await db.writeToDatabase({ id: "jwt", jwt: access_token });
          setUser(user)
          setToken(access_token)
          // let test = await makeRequest('GET', '/get-user')
          // if (test) {
          // }
        } else {
          throw new Error("Login Failed")
        }
      }).then(async () => {
        await sleep(1000);
        Notify.success("Login Successful");
        // access to in url
        let to = new URLSearchParams(window.location.search).get('to');
        if (to) {
          navigate(`/${to}`);
          return;
        }
        navigate("/dashboard");
      })
      .catch((error) => {
        let msg = handleError(error);
        setApiErrors(msg);
        Notify.failure(msg);
      }).finally(() => {
        setLoading(false);
      });
  };
  return (
    <AuthWrapper
      left={<>
        <Box sx={{

        }}>
          <Typography variant="h4" mb="1rem" fontWeight={'semibold'} align="left" gutterBottom>{
            to ? 'Just one more step' : 'Welcome Back'
          }</Typography>
          <Typography mb="1rem" variant="body2" align="left" gutterBottom>
            {to ? <>
              Login to your account to continue scheduling your meeting. If you don't have an account yet, kindly sign up to continue.
            </> : <>
              Login to your account to schedule meetings and manage projects.
            </>}
          </Typography>
          <DisplayAlerts apiErrors={apiErrors} />

          <form autoComplete="on" className="authForm" onSubmit={handleLogin}>
            <Grid
              container
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item></Grid>
              <Grid item></Grid>
            </Grid>

            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              autoComplete="email"
            />
            <PasswordField
              fullWidth
              label="Password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              disableCheck={true}
              aria-describedby="password-helper-text"
              autoComplete="current-password"

            />
            <Grid
              container
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item xs={6}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={<CheckBox defaultChecked />}
                  label="Remember Me"
                />
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="text" component={RouterLink} to="/forgot-password" sx={{
                  textAlign: 'left'
                }}>Forgot Password</Button>
              </Grid>
            </Grid>
            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              loading={loading}
            >
              Login
            </LoadingButton>

          </form>
          <Typography mt="2rem" align="left" variant="body2">
            Not yet registered?{" "}
            <Link component={RouterLink} to={`/register${to ? '?to=' + to : ''}`}>
              Sign Up
            </Link>
          </Typography>
        </Box>
      </>}

      right={<>
        <div
          style={{
            width: "80%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginSVG />
        </div>
      </>}
    />
  );
}

export default Login;
