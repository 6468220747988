import React, { useEffect } from 'react';
import { Avatar, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ChangePassword from '../components/ChangePassword';
import { userStore } from '../../zustand';
import { formatDate } from '../../util/functions';

function Account() {
  const { user, isLoading, getUser } = userStore()
  const styles = {
    noBorder: {
      borderBottom: 'none',
    },
    lastRow: {
      '&:last-child td, &:last-child th': { borderBottom: 'none' },
    },
  };
  useEffect(() => {
    getUser()
    console.log(user);
  }, [getUser])
  return (
    <>
      <Typography
        fontWeight={600}
        variant="h5" sx={{ marginBottom: 3 }}>Manage Account</Typography>
      {user ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Paper sx={{ padding: 3, marginTop: 3 }}>
              <Grid container spacing={2} flexDirection={'column'}>
                <Grid item
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'baseline',
                  }}
                >
                  <Avatar sx={{ width: 56, height: 56, marginBottom: 2 }}>
                    {user.first_name[0].toUpperCase()}
                  </Avatar>
                  <Typography variant="h5" textTransform={'capitalize'}>
                    {user.first_name} {user.last_name}
                  </Typography>
                </Grid>
                <Grid item>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {/* <TableRow>
                          <TableCell>Field</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow> */}
                      </TableHead>
                      <TableBody>
                        <TableRow sx={styles.lastRow}>
                          <TableCell sx={styles.noBorder}>Email</TableCell>
                          <TableCell sx={styles.noBorder}>{user.email}</TableCell>
                        </TableRow>
                        <TableRow sx={styles.lastRow}>
                          <TableCell sx={styles.noBorder}>Status</TableCell>
                          <TableCell sx={styles.noBorder}>{user.status === '1' ? 'Active' : 'Inactive'}</TableCell>
                        </TableRow>
                        <TableRow sx={styles.lastRow}>
                          <TableCell sx={styles.noBorder}>Email Verified</TableCell>
                          <TableCell sx={styles.noBorder}>{formatDate(user.email_verified_at)}</TableCell>
                        </TableRow>
                        <TableRow sx={styles.lastRow}>
                          <TableCell sx={styles.noBorder}>Member Since</TableCell>
                          <TableCell sx={styles.noBorder}>{formatDate(user.created_at)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <ChangePassword />
          </Grid>
        </Grid>
      ) : null}
      {!user && !isLoading ? (
        <Typography my={'2rem'} variant="body1" color="textSecondary">
          No user found
        </Typography>
      ) : null}
    </>
  );
}

export default Account;