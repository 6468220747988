import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordChecklist from "react-password-checklist";

const PasswordField = (props) => {
    const { label, name, value, onChange, error, helperText, autoComplete, disableCheck, type, ...rest } = props
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [err, setErr] = useState('');
    const [text, setText] = useState('');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    useEffect(() => {
        if (!disableCheck) {
            setErr(!isValid)
        }
    }, [isValid, value]);

    useEffect(() => {
        setErr(error);
    }, [error]);

    useEffect(() => {
        setText(helperText);
    }, [helperText]);

    return (
        <>
            <TextField
                fullWidth
                label={label}
                name={name}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                error={!!err}
                helperText={text || ''}
                autoComplete={autoComplete}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePasswordVisibility}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...rest}
            />

            {name === 'password' && isFocused &&
                !disableCheck && <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                    minLength={5}
                    value={value}
                    valueAgain={value}
                    onChange={(isValid) => {
                        setIsValid(isValid)
                    }}
                />}
        </>
    );
};

export default PasswordField;