import { Link, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as LogoIcon } from "../../assets/icono.svg";

export const Logo = ({ showText }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <>
            <Toolbar
                className="logo"
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <Link component={RouterLink} to={"/dashboard"}>
                    <span className="logo-icon"><LogoIcon /></span>
                    {(isDesktop || showText) &&
                        <Typography
                            variant="h5"
                            sx={{ textDecoration: "none" }}
                            fontWeight={"bold"}
                            align="center"
                            noWrap
                            component="div"
                        >
                            Doshby
                        </Typography>
                    }
                </Link>
            </Toolbar>
        </>
    );
};