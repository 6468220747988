import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container className='plain-page-container' maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Doshby Terms of Use
        </Typography>

        <Typography paragraph>
          Welcome to Doshby, a leading provider of cutting-edge software solutions. These Terms of Use ("Terms") govern your access to and interaction with our website, services, and products. By using our platform, you signify your agreement to these Terms. If you do not agree, please refrain from using our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Responsible Usage
        </Typography>
        <Typography paragraph>
          At Doshby, we are committed to fostering a secure and reliable digital environment. You agree to use our services solely for lawful purposes, adhering to all applicable laws and regulations. Activities that infringe upon the rights of others, compromise system security or integrity, or cause harm are strictly prohibited.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Intellectual Property Rights
        </Typography>
        <Typography paragraph>
          The content, trademarks, logos, and other intellectual property displayed on our website and within our services are the exclusive property of Doshby or its licensors. Unauthorized use, reproduction, modification, or distribution of these assets is strictly forbidden without our prior written consent.
        </Typography>

        <Typography variant="h6" gutterBottom>
          User Contributions
        </Typography>
        <Typography paragraph>
          If you choose to submit or upload content to our platform, you grant Doshby a perpetual, royalty-free, worldwide license to use, reproduce, modify, publish, and distribute the content in connection with our services. We respect your intellectual property rights and will handle your contributions responsibly.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Service Standards
        </Typography>
        <Typography paragraph>
          Doshby endeavors to provide reliable and secure services to our clients. However, we cannot guarantee uninterrupted, error-free, or flawless performance. Our services are provided on an "as is" and "as available" basis, without warranties of any kind, expressed or implied.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography paragraph>
          To the maximum extent permitted by law, Doshby shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of our services, including but not limited to loss of profits, data, or goodwill.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Indemnification
        </Typography>
        <Typography paragraph>
          You agree to indemnify, defend, and hold Doshby harmless from any claims, liabilities, damages, losses, or expenses, including reasonable legal fees, arising from your violation of these Terms or misuse of our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Modifications and Updates
        </Typography>
        <Typography paragraph>
          Doshby reserves the right to modify or update these Terms at any time without prior notice. Your continued use of our services after any revisions signifies your acceptance of the updated Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Governing Law
        </Typography>
        <Typography paragraph>
          These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions, concerns, or feedback regarding these Terms of Use, please contact our dedicated support team at <Link href="mailto:support@doshby.com">support@doshby.com</Link>. We are committed to addressing your inquiries promptly and ensuring your satisfaction with our services.
        </Typography>

        <Typography paragraph>
          At Doshby, we strive to foster a secure, transparent, and trustworthy environment for our clients as we collaborate on bringing their digital visions to life. These Terms of Use serve as a foundation for our partnership, ensuring responsible usage, protecting intellectual property rights, and upholding industry-leading standards of service and accountability.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;