import React, { useEffect } from 'react';
import HeaderMenu from './HeaderMenu';

function PageMenu({ open }) {
    useEffect(() => {
        if (open) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
        return () => {
            document.body.classList.remove('menu-open');
        };
    }, [open]);

    return (
        <>
            <div className="page-menu">
                <HeaderMenu force={true} useSec={true} />

            </div>
        </>
    );
}

export default PageMenu;