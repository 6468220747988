import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import SinglePortfolioView from './SinglePortfolioView';

const PortfolioViewer = ({ portfolios, id, setId, modalOpen, setModalOpen }) => {
    const [portfolioIds, setPortfolioIds] = useState([]);

    useEffect(() => {
        fetchPortfolioIds();
    }, [portfolios]);

    const fetchPortfolioIds = () => {
        setPortfolioIds(portfolios.map(p => p.id));
    };

    const currentItemNo = portfolioIds.indexOf(id);

    const handleNavigate = (direction) => {
        const currentIndex = portfolioIds.indexOf(id);
        let newIndex;

        if (direction === 'next') {
            newIndex = (currentIndex + 1) % portfolioIds.length;
        } else if (direction === 'prev') {
            newIndex = (currentIndex - 1 + portfolioIds.length) % portfolioIds.length;
        }

        const newId = portfolioIds[newIndex];
        setId(newId);
    };

    if (portfolioIds.length === 0) return <Typography>Loading portfolios...</Typography>;

    return (
        <Container>
            <SinglePortfolioView
                portfolioId={id}
                totalPortfolios={portfolioIds.length}
                currentItemNo={currentItemNo}
                onNavigate={handleNavigate}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
            />
        </Container>
    );
};

export default PortfolioViewer;