import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { motion } from "framer-motion";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../zustand/const";
import { useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function AppWrapper({ children }) {

  const [tag, setTag] = useState("")

  const theme = createTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
    typography: {
      fontFamily: "Inter, sans-serif",
      h1: {
        fontSize: '2.5rem',
        '@media (max-width:600px)': {
          fontSize: '2.5rem',
        },
        '@media (max-width:960px)': {
          fontSize: '3rem',
        },
      },
      h2: {
        fontSize: '2.25rem',
        '@media (max-width:600px)': {
          fontSize: '2.25rem',
        },
        '@media (max-width:960px)': {
          fontSize: '2.75rem',
        },
      },
      h3: {
        fontSize: '2.0rem',
        '@media (max-width:600px)': {
          fontSize: '1.75rem',
        },
        '@media (max-width:960px)': {
          fontSize: '2.25rem',
        },
      },
      h4: {
        fontSize: '1.75rem',
        '@media (max-width:600px)': {
          fontSize: '1.50rem',
        },
        '@media (max-width:960px)': {
          fontSize: '2.0rem',
        },
      },
      h5: {
        fontSize: '1.5rem',
        '@media (max-width:600px)': {
          fontSize: '1.25rem',
        },
        '@media (max-width:960px)': {
          fontSize: '1.75rem',
        },
      },
    },
  });

  const { pathname } = useLocation()
  useEffect(() => {
    const pages = ["/register", "/login", "/forgot-password", "/verify-email"]

    if (pages.includes(pathname)) {
      setTag("auth-page")
    } else {
      setTag("")
    }
  }, [pathname])

  const queryClient = new QueryClient();

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <motion.div
            className={`app-wrapper ${tag}`}>
            {children}
          </motion.div>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

export default AppWrapper;
