import React from "react";
import HeaderMenu from "./HeaderMenu";
import { Box, Button, Typography, useTheme, useMediaQuery, IconButton, Link } from "@mui/material";
import { RiMenu3Fill, RiCloseLargeLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import PageMenu from "./PageMenu";
import { motion } from "framer-motion";
import { userStore } from "../../zustand";
import { isEmpty } from "underscore";

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showMenu, setShowMenu] = React.useState(false);
  const { user } = userStore()


  return (
    <>
      <motion.header
        className="header-menu"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div>
          <Link color={'inherit'} component={RouterLink} to="/">
            <Typography variant="h4" align="center">
              Doshby
            </Typography>
          </Link>
        </div>
        {!isMobile && (
          <>
            <HeaderMenu />
            <Box className="" display={"flex"} gap="1rem" alignItems={'center'}>
              {isEmpty(user) ? <>
                <Button
                  LinkComponent={RouterLink}
                  to="/login"
                  className="btn-rounded btn-white"
                  color="secondary"
                >
                  Login
                </Button>
                <Button variant="contained" color="primary" LinkComponent={RouterLink} to="/register" className="btn-rounded">
                  Register
                </Button></> :
                <>
                  <Button
                    LinkComponent={RouterLink}
                    to="/dashboard"
                    className="btn-rounded btn-white"
                    color="secondary"
                    variant="outlined"
                  >
                    Dashboard
                  </Button>
                </>
              }
            </Box>
          </>
        )}
        {isMobile && (
          <>
            <IconButton
              sx={{ cursor: "pointer", marginLeft: "auto" }}
              onClick={() => setShowMenu(!showMenu)}
            >
              {showMenu ? <RiCloseLargeLine /> : <RiMenu3Fill />}
            </IconButton>
            {showMenu && (
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
              >
                <PageMenu open={showMenu} />
              </motion.div>
            )}
          </>
        )}
      </motion.header>
    </>
  );
}

export default Header;