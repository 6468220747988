import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./style/style.css";
import App from "./App";
import Login from "./public/pages/Login";
import Register from "./public/pages/Register";
import ForgotPassword from "./public/pages/ForgotPassword";
import ErrorPage from "./public/pages/ErrorPage";
import Home from "./dashboard/pages/Home";
import Project from "./dashboard/pages/Project";
import Account from "./dashboard/pages/Account";
import AboutUs from "./public/pages/AboutUs";
import Contact from "./public/pages/Contact";
import PrivacyPolicy from "./public/pages/PrivacyPolicy";
import TermsOfService from "./public/pages/TermsOfService";
import VerifyEmail from "./public/pages/VerifyEmail";
import Homepage from "./public/pages/Homepage";
import Dashboard from "./dashboard/components/DashboardWrapper";
import Meetings from "./dashboard/pages/Meetings";
import Billing from "./dashboard/pages/Billing";
import FAQ from "./public/pages/Faq";
import Schedule from "./public/pages/Schedule";
import Chat from "./dashboard/pages/Chat";
import Portfolio from "./public/pages/Portfolio";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/verify-email/:token?",
        element: <VerifyEmail />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/portfolio",
        element: <Portfolio />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/schedule",
        element: <Schedule />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "project/:id",
        element: <Project />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "meetings",
        element: <Meetings />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
