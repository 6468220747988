import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PortfolioList from '../components/PortfolioList'

function Portfolio() {
    return (
        <Box
            sx={{
                my: '2rem',
                pt: {
                    xs: '10vh',
                    sm: '8vh',
                },
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <Typography mb={4} align='center' fontWeight={500} variant="h5" gutterBottom>
                Portfolio
            </Typography>
            <Box>
                <PortfolioList />
            </Box>
        </Box>
    )
}

export default Portfolio