import React from 'react';
import Masonry from 'react-masonry-css';
import { Container } from '@mui/material';
import { motion } from 'framer-motion';
import homeimg1 from '../../assets/homeimg1.jpg';
import homeimg2 from '../../assets/homeimg2.jpg';
import homeimg3 from '../../assets/homeimg3.jpg';
import homeimg4 from '../../assets/homeimg4.jpg';

const images = [
    homeimg1,
    homeimg2,
    homeimg3,
    homeimg4
];

const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 2
};

const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
};

function MasonryGrid() {
    return (
        <Container>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {images.map((src, index) => (
                    <motion.div
                        key={index}
                        initial="hidden"
                        animate="visible"
                        variants={imageVariants}
                    >
                        <img src={src} alt={`${index + 1}`} style={{ width: '100%', maxHeight: `${index % 2 ? '350px' : '420px'}`, borderRadius: '8px' }} />
                    </motion.div>
                ))}
            </Masonry>
        </Container>
    );
}

export default MasonryGrid;