import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';

const BookingCard = ({ booking }) => {
    let style = {
        display: "flex",
        flexDirection: "column",
        gap: 16,
        textTransform: "capitalize"
    }
    return (
        <Card className='booking-card' style={{ marginBottom: 16 }}>
            <CardContent
                style={style}
            >
                <Typography variant="h6" gutterBottom>
                    {booking.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {booking.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Start At: {new Date(booking.start_at).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    End At: {new Date(booking.end_at).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Status: {booking.status === "0" ? "Inactive" : "Active"}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Created At: {new Date(booking.created_at).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Updated At: {new Date(booking.updated_at).toLocaleString()}
                </Typography>
            </CardContent>
        </Card>
    );
};




export default BookingCard;