import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, label, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeRequest } from '../util/AxiosInstance';
import { Notify } from 'notiflix';
import { API_URL } from '../zustand/const';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'underscore';
import { formatDateForApi, formatDateFromApi } from '../util/functions';
import DisplayAlerts from './DisplayAlerts';
import SuccessMsg from './SuccessMsg';
import { userStore } from '../zustand';
import moment from 'moment-timezone';


const BookingEditor = () => {
    let { id: editID } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [startAt, setStartAt] = useState('');
    // const [endAt, setEndAt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [step, setStep] = useState(1);
    const { user } = userStore()

    const userTimezone = moment.tz.guess();

    const clearAll = () => {
        setTitle('');
        setDescription('');
        setStartAt('');
        // setEndAt('');
        setSelectedTimeZone(null);
        setSuccessMsg(null);
        setApiErrors(null);
        setStep(1);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isEmpty(user)) {
            Notify.failure('Please select a recipient');
            return;
        }
        const endAt = moment(startAt).add(1, 'hour').format('YYYY-MM-DDTHH:mm');

        if (isEmpty(userTimezone) || isEmpty(title) || isEmpty(description) || isEmpty(startAt) || isEmpty(endAt)) {
            Notify.failure('Please fill all fields');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('start_at', formatDateForApi(startAt));
        formData.append('end_at', formatDateForApi(endAt));
        formData.append('start_at_time_zone', userTimezone);
        formData.append('end_at_time_zone', userTimezone);
        formData.append('user_id', user.id);

        if (editID) {
            formData.append('status', 1);
        }
        setIsLoading(true);

        try {
            makeRequest('POST', editID ? `${API_URL}/edit-booking/${editID}` : `${API_URL}/create-booking`, formData)
                .then((response) => {
                    if (response.data.status) {
                        Notify.success('Booking created successfully');
                        setSuccessMsg('Booking created successfully');
                        setStep(2);
                    } else {
                        Notify.failure('Failed to create booking');
                    }
                }).catch((error) => {
                    Notify.failure('Failed to create booking: ' + error.message);
                    console.error('Error:', error);
                    setApiErrors(error.response.data.message ?? error.message);
                }).finally(() => {
                    setIsLoading(false);
                })
        } catch (error) {
            Notify.failure('Failed to create booking: ' + error.message);
            console.error('Error:', error);
        }
    };

    return (
        <>
            <DisplayAlerts success={successMsg} apiErrors={apiErrors} />
            {step === 1 && (
                <Box component="form" className='booking-form' onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        backgroundColor: 'white',
                        padding: 2,
                        width: '100%',
                    }}>
                    <FormControl fullWidth margin="normal">
                        <label htmlFor="title">
                            Title
                        </label>
                        <TextField
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <label htmlFor="description">
                            Description
                        </label>
                        <TextField
                            multiline
                            rows={4}
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <label htmlFor="start-at">
                            Choose a time.
                        </label>
                        <TextField
                            id="start-at"
                            type="datetime-local"
                            value={startAt}
                            onChange={(e) => setStartAt(e.target.value)}
                            required

                        />
                    </FormControl>
                    {/* <FormControl fullWidth margin="normal">
                        <label htmlFor="end-at">
                            End At
                        </label>
                        <TextField
                            id="end-at"
                            type="datetime-local"
                            value={endAt}
                            onChange={(e) => setEndAt(e.target.value)}
                            required

                        />
                    </FormControl> */}
                    <LoadingButton
                        loading={isLoading}
                        type="submit" variant="contained" color="primary">
                        Schedule
                    </LoadingButton>
                </Box>
            )}

            {step === 2 && <SuccessMsg text={'Booking created successfully.'} label={'Dashboard'} target={'dashboard'} clear={clearAll} setStep={setStep} />}
        </>

    );
};

export default BookingEditor;