import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { makeRequest } from '../../util/AxiosInstance';
import SuccessSVG from '../../assets/verified.svg'; // Assuming you have an SVG for success
import ErrorSVG from '../../assets/error.svg'; // Assuming you have an SVG for error
import { Notify } from 'notiflix';
import { handleError } from '../../util/functions';
import DisplayAlerts from '../../shared/DisplayAlerts';

function VerifyEmail() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error', 'redirecting'
    const [err, setErr] = useState(null);
    useEffect(() => {
        if (!token) {
            setStatus('redirecting');
            setTimeout(() => {
                navigate('/');
            }, 5000);
            return;
        }

        const verify = async () => {
            try {
                await makeRequest('get', `/verify-email/${token}`);
                setStatus('success');
            } catch (error) {
                Notify.failure(handleError(error));
                setErr(handleError(error));
                setStatus('error');
            }
        };
        verify();
    }, [token, navigate]);

    const resend = () => {
        makeRequest('get', '/send-email-verification')
            .then((response) => {
                Notify.success(response.data.message);
            })
            .catch((error) => {
                Notify.failure(handleError(error));
                setErr(handleError(error));
            });
    }

    if (status === 'redirecting') {
        return (
            <Box className="plain-page-container" sx={{ textAlign: 'center', mt: 5 }}>
                <Typography variant="h6">No token provided. Redirecting...</Typography>
            </Box>
        );
    }

    return (
        <Box className="plain-page-container" sx={{ display: 'flex', gap: '1rem', flexDirection: 'column', alignItems: 'center', mt: 5, minHeight: '500px', minWidth: '500px' }}>

            {status === 'loading' && (
                <>
                    <CircularProgress />
                    <Typography variant="h6" mt={2}>Verifying your email...</Typography>
                </>
            )}
            {status === 'success' && (
                <>
                    <img src={SuccessSVG} alt="Success" width={300} height={300} />
                    <Typography variant="h6" mt={2}>Email verified successfully!</Typography>
                    <Button mt={2} variant="contained" color="primary" onClick={() => navigate('/login')}>Login</Button>
                </>
            )}
            {status === 'error' && (
                <>
                    <img src={ErrorSVG} alt="Error" width={300} height={300} />
                    <DisplayAlerts apiErrors={err} />
                    <Typography variant="h6" mt={2}>Failed to verify email. Please try again.</Typography>
                    <Button mt={2} onClick={resend} variant="contained" color="primary">Resend</Button>
                </>
            )}
        </Box>
    );
}

export default VerifyEmail;