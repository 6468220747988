import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TbMessageCircleBolt } from "react-icons/tb";
import ProjectDetails from "../components/ProjectDetail";
import Chat from "../components/Chat";
import { CiSettings } from "react-icons/ci";
import { makeRequest } from "../../util/AxiosInstance";
import { handleError } from "../../util/functions";
import { Block, Notify } from "notiflix";
import { isEmpty } from "underscore";
import BackButton from "../../shared/BackButton";
import Placeholder from "../../shared/Placeholder";

function Project() {
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    Block.circle(".project-detail");
    makeRequest("GET", `/get-project/${id}`)
      .then((response) => {
        setProject(response.data.data);
      })
      .catch((error) => {
        let msg = handleError(error);
        Notify.failure(msg);
        console.log(error);
      })
      .finally(() => {
        Block.remove(".project-detail");
      });
  }, [id]);

  return (
    <>
      <motion.div className="project-detail">
        {!isEmpty(project) && (
          <>
            <Box>
              <BackButton />
            </Box>
            <Box mt={2} mb={"2rem"} display={"flex"} alignItems={"center"}>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Typography
                  textTransform={"capitalize"}
                  variant="h5"
                  fontWeight={"600"}
                >
                  {project.title ?? "Project name"}
                </Typography>

              </Box>
            </Box>
            <Box>
              <TabContext value={value}>
                <Tabs
                  className="tab-bar"
                  value={value}
                  onChange={handleChange}
                  textColor="inherit"
                  indicatorColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    className="tab-item"
                    icon={<IoMdInformationCircleOutline />}
                    iconPosition="start"
                    label="Details"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="tab-item"
                    icon={<TbMessageCircleBolt />}
                    iconPosition="start"
                    label="Messaging"
                    {...a11yProps(1)}
                  />
                </Tabs>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel sx={{
                    px: {
                      xs: 1,
                      sm: 2,
                    }
                  }} value={value} index={0} dir={theme.direction}>
                    <div>
                      {project.status ? (
                        <ProjectDetails project={project} />
                      ) : (
                        <Typography variant="body1" color="error">
                          {project.message}
                        </Typography>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel
                    className="chat-tab"
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <Chat dm={{
                      id: id,
                      title: project.title
                    }} />
                  </TabPanel>
                </SwipeableViews>
              </TabContext>
            </Box>
          </>
        )}
        {isEmpty(project) && <Placeholder text={"No project found"} />}
      </motion.div>
    </>
  );
}

export default Project;
