import React, { useState, useEffect } from "react";
import { CiMenuKebab } from "react-icons/ci";
import { IconButton, Menu, MenuItem, Typography, Button } from "@mui/material";
import { Block } from "notiflix";
import { LoadingButton } from "@mui/lab";
import { isEmpty } from "underscore";
import { useMeetingStore } from "../../zustand";
import { ReactComponent as NoMeeting } from "../../assets/no_data.svg";
import Placeholder from "../../shared/Placeholder";

function MeetingItem({ meeting }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="meeting-item">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1" fontWeight={"semibold"}>
          {meeting.title}
        </Typography>
        <div>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <CiMenuKebab />
          </IconButton>
          <Menu open={open}>
            <MenuItem>Edit</MenuItem>
            <MenuItem>Cancel</MenuItem>
          </Menu>
        </div>
      </div>
      <Typography variant="body1" sx={{ mt: 1 }}>
        <span>{meeting.time}</span>
        <span>{meeting.date}</span>
      </Typography>
    </div>
  );
}

function MeetingItems() {
  const { meetings, hasMore, isLoading, currentPage, setCurrentPage, fetchMeetings } = useMeetingStore();

  useEffect(() => {
    if (isLoading) {
      Block.circle('.meeting-list')
    } else {
      Block.circle('.meeting-list')
      Block.remove('.meeting-list')
    }
  }, [isLoading]);

  useEffect(() => {
    fetchMeetings(currentPage);
  }, [currentPage, fetchMeetings]);

  const loadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="meeting-list">
      {!isEmpty(meetings) ? meetings.map((meeting) => (
        <MeetingItem key={meeting.id} meeting={meeting} />
      )) : <>
        <Placeholder text={'No meetings found.'} icon={NoMeeting} />
      </>}
      {!isEmpty(meetings) && hasMore && (
        <LoadingButton
          loading={isLoading}
          onClick={loadMore} variant="contained" color="primary">
          Load More
        </LoadingButton>
      )}
    </div>
  );
}

export default MeetingItems;