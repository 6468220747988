import React from "react";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { Box } from "@mui/system";
import AppWrapper from "./public/components/AppWrapper";
import Footer from "./public/components/Footer";
import Header from "./public/components/Header";

function App() {
  return (
    <AppWrapper>
      <Header />
      <Box component={motion.main}>
        <Outlet />
      </Box>
      <Footer />
    </AppWrapper>
  );
}
export default App;
