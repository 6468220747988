import { Typography } from '@mui/material';
import React from 'react';

const GreetingComponent = () => {
    // Get current date and time
    const now = new Date();
    const hours = now.getHours();
    let greeting;

    // Determine greeting based on the current hour
    if (hours < 12) {
        greeting = 'Good morning';
    } else if (hours < 18) {
        greeting = 'Good afternoon';
    } else {
        greeting = 'Good evening';
    }

    // Format the date as "Today, 06 July 2022"
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const today = now.toLocaleDateString('en-US', options);

    return (
        <div>
            <Typography variant='h6'>
                {greeting}
            </Typography>
            <Typography className='greeting-text' variant='subtitle2'>
                Today, {today}
            </Typography>
        </div>
    );
};

export default GreetingComponent;