export const IndexedDbName = "doshby";
export const IndexDbStoreName = "doshby-store";
export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const PRIMARY_COLOR = "#000687";
export const SECONDARY_COLOR = "#878000";
export const BillingOrderStatus = {
    PENDING: 0,
    SUCCESS: 1,
    FAILED: 2,
    CANCELLED: 3,
    REFUNDED: 4
}