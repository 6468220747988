import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Chip, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material";
import { LuCalendarPlus } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { getPaymentLink, handleError } from "../../util/functions";
import { GoArrowRight } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import { Notify } from "notiflix";
import { LoadingButton } from "@mui/lab";


function ProjectItem({ project }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  const calculateDaysLeft = (futureDateString) => {
    const futureDate = new Date(futureDateString);
    const currentDate = new Date();
    const timeDifference = futureDate - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysLeft;
  }

  const handleNavigate = (project) => {
    navigate(`/dashboard/project/${project.id}`);
  }
  const handlePayment = async (id) => {
    setIsLoading(true);
    getPaymentLink(id).then((link) => {
      console.log(link);
      window.open(link, "_blank");
    }).catch((error) => {
      let msg = handleError(error);
      Notify.failure(msg);
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  // console.log(project);

  return (
    <motion.div className="project-item" >
      <div>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography variant="body1" fontWeight={"500"} mb={1}>
              {project.name ?? `Project ${project.id ?? "#"}`}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
          }}>
            <Button color="secondary" onClick={() => handleNavigate(project)} variant="text" size="small"
            // endIcon={<FaArrowRight />}
            >
              Open
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body2" mt={2}>{project.description ?? ""}</Typography>
      </div>
      {project.hasOwnProperty('project_order') && +project.project_order.status == 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Chip color="secondary" label={'Unpaid'} />
          <LoadingButton
            size="small"
            onClick={() => handlePayment(project.id)}
            variant="text"
            loading={isLoading}
            color="primary">Pay Now</LoadingButton>
        </div>
      )}
      <Divider sx={{
        marginTop: 'auto'
      }} />
      <Grid container spacing={2}
        style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <Typography
            className="useLightColor"
            variant="caption">{calculateDaysLeft(project.project_detail.end_at)} days left</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            className="useLightColor"
            variant="caption" style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "0.3rem",
            }}>
            <span style={{
              fontSize: "1rem",
            }}><LuCalendarPlus /></span>
            <span>
              {formatDate(project.created_at)}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </motion.div>
  );
}

function ProjectItemSkeleton() {
  return (
    <Box
      className="project-item"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        mb: 2,
        borderRadius: '1rem',
        bgcolor: 'background.paper',
      }}>
      <Skeleton variant="text" width="60%" height={16} />
      <Skeleton variant="text" width="40%" height={32} sx={{ mt: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Skeleton variant="rectangular" width={100} height={20} />
        <Skeleton variant="rectangular" width={100} height={20} />
      </Box>
    </Box>
  );
}

export { ProjectItemSkeleton };
export default ProjectItem;
