import { makeRequest } from "./AxiosInstance";

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
export function handleError(error) {
    let errorMessage = "An unknown error occurred";
    console.error(error)
    if (typeof error === 'string') {
        errorMessage = error;
    } else if (error && typeof error === 'object') {
        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.message) {
            errorMessage = error.message;
        } else {
            errorMessage = 'Sorry, there is an error.';
        }
    }
    return errorMessage
}
export const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};
export const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};
export const convertToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString();
};
export const getPaymentLink = async (id) => {
    return makeRequest('GET', `/get-project-payment-link/${id}`).then((response) => {
        console.log(response);
        return response.data.data;
    })
}

export function formatDateForApi(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const formatDateTime = (dateString) => {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate} ${formattedTime}`;
};

export function formatDateFromApi(dateString) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const nth = (d) => {
        if (d > 3 && d < 21) return 'th'; // special case for numbers between 11 and 20
        switch (d % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const d = new Date(dateString);
    const dayOfWeek = daysOfWeek[d.getDay()];
    const day = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    let hours = d.getHours();
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${dayOfWeek} ${day}${nth(day)} ${month}, ${year}. ${hours}:${minutes}${ampm}`;
}
