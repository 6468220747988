import React from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { margin, padding } from '@mui/system';
import { isEmpty } from 'underscore';

const DisplayAlerts = ({ apiErrors, message, success }) => {
    const style = {
        padding: '0px',
        margin: '10px 0',
    };

    const renderContent = (data, severity) => {
        if (!isEmpty(data)) {
            if (typeof data === 'string') {
                return <Alert severity={severity}>{data}</Alert>;
            } else if (Array.isArray(data)) {
                return (
                    <Alert style={style} severity={severity} icon={false}>
                        <List>
                            {data.map((error, index) => (
                                <ListItem key={index}>{error}</ListItem>
                            ))}
                        </List>
                    </Alert>
                );
            } else if (typeof data === 'object') {
                return (
                    <Alert style={style} severity={severity} icon={false}>
                        <List>
                            {Object.keys(data).map((key, index) => (
                                <ListItem key={index}>{data[key]}</ListItem>
                            ))}
                        </List>
                    </Alert>
                );
            }
        }
    }

    return (
        <>
            {renderContent(apiErrors, 'error')}
            {renderContent(message, 'info')}
            {renderContent(success, 'success')}
        </>
    );
};

export default DisplayAlerts;