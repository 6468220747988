import React from 'react'
import { HiOutlineLogout } from "react-icons/hi";
import { DashboardMenuContainer } from './DashboardMenu';
import { userStore } from '../../zustand';
function DashboardMenuFooter() {
    const { logout } = userStore()
    const listItem = [
        {
            text: "Logout",
            icon: <HiOutlineLogout />,
            slug: "logout",
            action: logout
        },
    ];
    return (
        <DashboardMenuContainer listItem={listItem} classList={'dashboard-menu-footer'} />
    )
}

export default DashboardMenuFooter