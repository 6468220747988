import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { MdArrowBack } from "react-icons/md";

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <IconButton
            mr={2}
            sx={{
                marginRight: '1rem',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                maxWidth: '40px',
                marginBottom: '1rem'
            }}
            onClick={() => navigate(-1)} aria-label="back">
            <MdArrowBack />
        </IconButton>
    );
};

export default BackButton;