import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as LoginSVG } from "../../assets/login.svg";
import { LoadingButton } from "@mui/lab";
import { Notify } from "notiflix";
import AuthWrapper from "../components/AuthWrapper";
import { makeRequest } from "../../util/AxiosInstance";
import { isEmpty } from "underscore";
import DisplayAlerts from "../../shared/DisplayAlerts";

function ForgotPassword() {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [apiErrors, setApiErrors] = useState(null);
  const [apiSuccessMsg, setApiSuccessMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setApiErrors(null);
    setApiSuccessMsg(null);
    if (isEmpty(formData.email)) {
      setApiErrors("Please enter a valid email address");
      return false;
    }
    setLoading(true);
    makeRequest('post', '/forgot-password', {
      email: formData.email,
    })
      .then((response) => {
        const { status, message } = response.data
        if (status) {
          setFormData({
            email: "",
          });
          setApiSuccessMsg(message)
        }
      })
      .catch((error) => {
        Notify.failure("Request Failed")
        setApiErrors(error.response.data.message ?? error.response.data)
      }).finally(() => {
        setLoading(false);
      });
  };
  return (
    <AuthWrapper

      left={<>
        <div>
          <Typography variant="h4" mb="1rem" fontWeight={'semibold'} align="left" gutterBottom>Forgot Password</Typography>
          <Typography mb="1rem" variant="body2" align="left" gutterBottom>
            Enter your email to reset your password
          </Typography>
          <DisplayAlerts apiErrors={apiErrors} success={apiSuccessMsg} />

          <form autoComplete="on" className="authForm" onSubmit={handleLogin}>
            <Grid
              container
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item></Grid>
              <Grid item></Grid>
            </Grid>

            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              autoComplete="email"
            />
            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              color="primary"
              loading={loading}
            >
              Submit
            </LoadingButton>

          </form>
        </div>
      </>}

      right={<>
        <div
          style={{
            width: "80%",
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginSVG />
        </div>
      </>}
    />
  );
}

export default ForgotPassword;
