import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userStore } from '../../zustand';
import { isEmpty } from 'underscore';

const AuthWrapper = ({ children }) => {
    const navigate = useNavigate();
    const { token, logout } = userStore()

    useEffect(() => {
        if (isEmpty(token)) {
            logout()
            navigate('/login');
        }
    }, [token]);

    if (!isEmpty(token)) {
        return (
            <>
                {children}
            </>
        );
    } else {
        navigate('/login');
    }
};

export default AuthWrapper;