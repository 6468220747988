import React, { useEffect, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Grid,
  TextField,
  Link,
  Typography,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { each } from "underscore";
import { makeRequest } from "../../util/AxiosInstance";
import AuthWrapper from "../components/AuthWrapper";
import { ReactComponent as Onboard } from "../../assets/onboard.svg";
import PasswordField from "../../shared/PasswordField";
import DisplayAlerts from "../../shared/DisplayAlerts";
import { Notify } from "notiflix";


function SuccessAction() {
  let to = new URLSearchParams(window.location.search).get('to');

  return (<div className="reg-success">
    <div className="icon">
      <Onboard />
    </div>
    <Typography variant="h4" mb="1rem" fontWeight={'semibold'} align="left" gutterBottom>Welcome on board!</Typography>
    <Typography variant="body2" mb="1rem">You have successfully registered. Please check your email to verify your account.</Typography>
    <Button variant="contained" color="primary" component={RouterLink}
      to={`/login${to ? '?to=' + to : ''}`}
    >Login</Button>
  </div>)
}


function Register() {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("register"); // ["success", "register"]
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [apiErrors, setApiErrors] = useState(null);
  let to = new URLSearchParams(window.location.search).get('to');

  const validateField = (name, value) => {
    switch (name) {
      case 'email':
        return /\S+@\S+\.\S+/.test(value) ? '' : 'Invalid email address';
      case 'password':
        return value.length >= 8 ? '' : 'Password must be at least 8 characters';
      case 'confirmPassword':
        return value === formData.password ? '' : 'Passwords do not match';
      default:
        return value ? '' : 'This field is required';
    }
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRegister = (e) => {
    e.preventDefault()
    let formIsValid = true;
    const newErrors = {};
    each(formData, (value, key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        formIsValid = false;
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);
    if (!formIsValid) return;

    setLoading(true)
    makeRequest("post", "/signup", {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password: formData.password,
    }).then((response) => {
      if (response.status === 200) {
        setMode("success");
        Notify.success("Registration successful. Please check your email to verify your account.");
      }
      // console.log(response.data.data.access_token);
    }).catch((error) => {
      const { errors } = error.response.data;
      if (errors) {
        setApiErrors(errors);
        console.log(errors);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <AuthWrapper left={
        <>
          {mode === "success" && <SuccessAction />}
          {mode === "register" && <>
            <Typography variant="h4" mb="1rem" fontWeight={'semibold'} align="left" gutterBottom>Join The Doshby Experience</Typography>
            <Typography variant="body2" mb="1rem">Create Your Account to Start Managing Projects</Typography>
            <div className="form">
              <DisplayAlerts apiErrors={apiErrors} />
              <form autoComplete="on" className="authForm" onSubmit={handleRegister}>
                <Grid
                  container
                  sx={{ alignItems: "center", justifyContent: "space-between" }}
                >
                  <Grid item></Grid>
                  <Grid item></Grid>
                </Grid>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}

                />
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}

                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  error={!!errors.email}
                  helperText={errors.email || ''}
                  autoComplete="email"

                />
                <PasswordField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={!!errors.password}
                  helperText={errors.password || ''}
                  autoComplete="new-password"

                />
                <PasswordField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword || ''}
                  autoComplete="new-password"

                />
                <LoadingButton
                  name="submit"
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  loading={loading}
                >
                  Register
                </LoadingButton>

              </form>
              <Typography mt="2rem" align="left" variant="body2">
                Already have an account?{" "}
                <Link component={RouterLink} to={`/login${to ? '?to=' + to : ''}`}>
                  Login
                </Link>
              </Typography>
            </div>
          </>}
        </>
      }
        right={<>
          <div
            style={{
              width: "80%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="round"></div>
          </div>
        </>}
      />
    </>
  );
}

export default Register;
