import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    ConversationHeader,
    InfoButton,
    TypingIndicator,
    ConversationList,
    Conversation,
    Avatar,
    Sidebar,
    SendButton,
} from "@chatscope/chat-ui-kit-react";
import { Box, useMediaQuery } from '@mui/system';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { userStore } from '../../zustand';
import { isEmpty } from 'underscore';
import { IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API_URL } from '../../zustand/const';
import ReactLinkify from 'react-linkify';
import FileMessage from './FileMessage';
import useDebounce from './Debounce';
import { CiSearch } from 'react-icons/ci';
import { IoCloseOutline } from 'react-icons/io5';
import echo from './Echo';
import he from 'he';
import { sleep } from '../../util/functions';
import '../../style/chat.css'
import { makeRequest } from '../../util/AxiosInstance';


const createPreviewUrl = (file) => {
    return new Promise((resolve, reject) => {
        if (!file || !(file instanceof File)) {
            reject(new Error('Invalid file object'));
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
    });
};

const DMS_NO = 21

function ChatComponent({ dm }) {
    const [activeDm, setActiveDm] = useState(null);
    const [isTypingUser, setIsTypingUser] = useState(null);
    const { user } = userStore()
    const [queryKey, setQueryKey] = useState(null)
    const [messagesForDm, setMessagesForDm] = useState(null);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openContentBox, setOpenContentBox] = useState(false);
    const [textInputValue, setTextInputValue] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300);
    const [showSearch, setShowSearch] = useState(false);
    const hasNextPageRef = useRef(null);
    //
    const queryClient = useQueryClient();
    const mainContainerRef = useRef(null);
    const fileInputRef = useRef(null);
    const [setFileUploads] = useState({});
    const [mainContainerWidth, setMainContainerWidth] = useState(0);

    const isFetchingRef = useRef(false);

    // const [dm, setDm] = useState(dm)
    useEffect(() => {
        setActiveDm(dm)
    }, [dm])

    const fetchDms = async ({ pageParam = 1 }) => {
        if (dm) return;
        let user_id = userStore.getState().user.id
        if (!user_id || isFetchingRef.current) {
            return;
        }
        if (!pageParam) {
            pageParam = 1
        }
        try {
            isFetchingRef.current = true;
            let params = new URLSearchParams()
            params.append('page', pageParam)
            params.append('perPage', DMS_NO)
            if (search) {
                params.append('title', search)
            }

            return await makeRequest('GET', `${API_URL}/projects/chat?${params.toString()}`)
                .then((response) => {
                    let res = response.data.data
                    let dd = res.data
                    return {
                        data: dd,
                        current_page: res.current_page,
                        last_page: res.last_page,
                        total: res.total
                    }
                }).catch((error) => {
                    console.error("Failed to fetch messages", error);
                })
        } catch (error) {
            console.error('Error fetching messages', error);
        } finally {
            isFetchingRef.current = false;
        }
    }

    const {
        data: dmsData,
        isLoading: isLoadingDms,
        fetchNextPage: fetchNextsDms,
        hasNextPage: hasNextPageDms,
        isFetchingNextPage: isFetchingNextPageDms,
        status: statusDms,
        refetch: refetchDms
    } = useInfiniteQuery({
        queryKey: ['dms', user.id, debouncedSearch],
        queryFn: fetchDms,
        getNextPageParam: (lastPage) => {
            if (!lastPage) return undefined;
            let nextPage = lastPage.current_page + 1 <= lastPage.last_page ? lastPage.current_page + 1 : undefined
            return nextPage
        },
        enabled: !!user.id
    });

    useEffect(() => {
        hasNextPageRef.current = hasNextPageDms;
    }, [hasNextPageDms]);

    const dms = useMemo(() => dmsData?.pages.flatMap(page => page?.data) ?? [], [dmsData]);

    const isLoadingRef = useRef(false);
    const handleScrollToEnd = useCallback(() => {
        console.log('hasNextPageRef.current: ', hasNextPageRef.current);
        if (isLoadingRef.current || !hasNextPageRef.current) return;
        isLoadingRef.current = true;
        fetchNextsDms().finally(() => {
            isLoadingRef.current = false;
        });
    }, [fetchNextsDms]);


    useEffect(() => {
        if (dms && dms.length > 0) {
            dms
                .filter(dm => !isEmpty(dm) && dm.hasOwnProperty('id'))
                .forEach(async (dm) => {
                    echo.private(`projects.${dm.id}`)
                        .listen('.projectChat.created', (e) => {
                            console.log(e.project);
                            console.log(e.projectChat);
                        })
                        .listen('.projectChat.updated', (e) => {
                            console.log(e.project);
                            console.log(e.projectChat);
                        })
                        .listen('.projectChat.deleted', (e) => {
                            console.log(e.project);
                            console.log(e.projectChat);
                        })
                    await sleep(500)
                })
        }
    }, [dms])

    const fetchMessages = async (page, projectId) => {
        if (!projectId) {
            return
        }
        const response = await makeRequest('GET', `${API_URL}/get-project-chats/${projectId}?page=${page}&perPage=100`)
        let dd = response.data.data
        return dd
    }

    /**
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     * MESSAGES
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     */


    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['messages', activeDm?.id],
        queryFn: ({ pageParam = 1 }) => fetchMessages(pageParam, activeDm?.id),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => lastPage?.to ?? undefined,
        enabled: !!queryKey,
    });

    const {
        data: createMsgData, error: createMsgError, mutateAsync: sendMsgMutation
    } = useMutation({
        mutationFn: async (messageData) => {
            if (!queryKey) return;

            let fd = new FormData();
            let headers = {};
            let url = '/create-project-chat';

            if (+messageData.project_chat_type === 2) { // Assuming 2 is the file upload type
                // File upload
                // fd.append('file', messageData.file);
                fd.append('project_id', messageData.project_id);
                fd.append('project_chat_type', messageData.project_chat_type);
                fd.append('project_chat_content', messageData.project_chat_content);

                // Set the correct headers for file upload
                headers = {
                    'Content-Type': 'multipart/form-data'
                };

            } else {
                // Regular message
                fd.append('project_chat_content', messageData.project_chat_content);
                fd.append('project_chat_type', messageData.project_chat_type);
                fd.append('project_id', messageData.project_id);
            }

            // Add sender ID to all requests
            fd.append('senderId', user.id);

            return makeRequest('POST', url, fd,
                { ...headers },
                {
                    onUploadProgress: (progressEvent) => {
                        if (!progressEvent.total) return;
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log('Upload progress:', percentCompleted);
                        if (messageData.project_chat_type === 2) {
                            updateFileUploadProgress(messageData.id, percentCompleted);
                        }
                    }
                }
            );
        },

        onMutate: async (newMessage) => {
            if (!queryKey) return;
            await queryClient.cancelQueries(queryKey);

            const previousMessages = queryClient.getQueryData(queryKey);

            let newMsg = {
                id: 'temp-id-' + Date.now(),
                created_at: Date.now(),
                updated_at: Date.now(),
                project_chat_type: newMessage.project_chat_type,
                project_chat_sender_user_id: user.id
            }
            let project_chat_content = null
            if (typeof newMessage.project_chat_content === 'object') {
                project_chat_content = {
                    name: newMessage.project_chat_content.name,
                    content: await createPreviewUrl(newMessage.project_chat_content),
                    status: 0,
                }
            } else {
                project_chat_content = {
                    content: newMessage.project_chat_content,
                    status: 0,
                }
            }

            newMsg.project_chat_content = project_chat_content

            console.log(newMsg);

            if (newMessage.project_chat_type === 2) {
                newMsg = {
                    ...newMsg,
                    ...newMessage,
                }
            }

            queryClient.setQueryData(queryKey, (old) => {
                if (!old) return { pages: [{ data: [newMsg] }], pageParams: [1] };
                const newPages = [...old.pages];
                const lastPageIndex = newPages.length - 1;

                newPages[lastPageIndex] = {
                    ...newPages[lastPageIndex],
                    data: [newMsg, ...newPages[lastPageIndex].data]
                };

                let dd = {
                    ...old,
                    pages: newPages
                };
                return dd
            });
            return { previousMessages };
        },

        onError: (err, variables, context) => {
            if (variables && variables.project_chat_type === 2) {
                updateFileUploadStatus(variables.id, 'error', null, err.message);
            }
            console.log(err);
        },
        onSettled: (data, variables) => {
            console.log('data: ', data, variables);
            // queryClient.invalidateQueries(queryKey);
            if (variables && variables.project_chat_type === 2) {
                console.log('data: ', data);
                updateFileUploadStatus(variables.id, 'success', data?.fileUrl);
            }
        },
        enabled: !!queryKey
    });

    const updateFileUploadProgress = (fileId, progress) => {
        setFileUploads(prev => ({
            ...prev,
            [fileId]: { ...prev[fileId], progress }
        }));
    };

    const updateFileUploadStatus = (fileId, status, url = null, error = null) => {
        setFileUploads(prev => ({
            ...prev,
            [fileId]: { ...prev[fileId], status, url, error }
        }));
    };

    const formatData = (projectChat) => {
        if (!projectChat) return;
        const msg = {
            name: '',
            status: 0,
            senderId: '',
            content: '',
            createdAt: ''
        }
        const { project_chat_content: { content, status }, project_chat_sender_user_id, project_chat_type, updated_at } = projectChat

        msg.content = content
        if (projectChat.project_chat_content.hasOwnProperty('name')) {
            msg.name = projectChat.project_chat_content.name
        }

        if (projectChat.project_chat_content.hasOwnProperty('status')) {
            msg.status = status
        }
        msg.senderId = +project_chat_sender_user_id
        msg.createdAt = updated_at
        msg.type = project_chat_type
        return msg
    }

    const initiateFileUpload = () => {
        console.log('initiateFileUpload');
        fileInputRef.current.click();
    };

    const uploadFile = (event) => {
        const file = event.target.files[0];
        console.log('File to upload: ', file);
        if (file) {
            const fileId = 'file-' + Date.now();
            let newFileUpload = {
                id: fileId,
                project_chat_content: file,
                project_id: activeDm.id,
                project_chat_type: 2,
                file: file,
                progress: 0,
                status: 'uploading',
                url: null,
                error: null
            }
            setFileUploads(prev => ({ ...prev, [fileId]: newFileUpload }));
            sendMsgMutation(newFileUpload, 2);
            fileInputRef.current.value = '';
        }
    };

    const handleSendMessage = () => {
        if (!textInputValue) return;

        let messageData = {
            project_chat_content: textInputValue,
            project_id: activeDm.id,
            project_chat_type: 1
        }
        sendMsgMutation(messageData);
        setTextInputValue('');
    }

    const handleUserTyping = (message) => {
        setTextInputValue(message);
    }
    const renderStatus = (data) => {
        return 'testing'
        if (data && data.user && isTypingUser === data.user.id) {
            return "typing";
        } else if (data.last_msg) {
            return data.last_msg.message;
        } else {
            return "Last seen long time ago";
        }
    };
    /**
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     * USE EFFECTS
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     */

    useEffect(() => {
        let allData = []
        if (data && data.hasOwnProperty('pages')) {
            data.pages.map((page) => {
                allData = allData.concat(page?.data)
            })
        }
        allData = allData.map(formatData).reverse()
        setMessagesForDm(allData)
    }, [data])

    useEffect(() => {
        if (isMobileScreen || mainContainerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [isMobileScreen, mainContainerWidth])

    useLayoutEffect(() => {
        if (mainContainerRef.current) {
            setMainContainerWidth(mainContainerRef.current.offsetWidth);
        }
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            if (mainContainerRef.current) {
                setMainContainerWidth(mainContainerRef.current.offsetWidth);
            }
        };

        const resizeObserver = new ResizeObserver(updateWidth);

        if (mainContainerRef.current) {
            resizeObserver.observe(mainContainerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);


    useEffect(() => {
        if (openContentBox) {
            if (!document.body.classList.contains('hide-footer')) {
                document.body.classList.add('hide-footer');
            }
        } else {
            if (document.body.classList.contains('hide-footer')) {
                document.body.classList.remove('hide-footer');
            }
        }
    }, [openContentBox])

    useEffect(() => {
        if (!isEmpty(activeDm)) {
            (async () => {
                setOpenContentBox(true);
            })()
        } else {
            setMessagesForDm(null);
            setOpenContentBox(false);
        }
        setQueryKey(['messages', activeDm?.id])
    }, [activeDm])

    useEffect(() => {
        if (error) {
            console.log('error: ', error);
        }
        if (createMsgError) {
            console.log('createMsgError: ', createMsgError);
        }
    }, [error, createMsgError])



    return (
        <Box className='chat-wrapper' ref={mainContainerRef} style={{ flex: 1 }}>
            <MainContainer style={{ flex: 1 }}>
                {
                    ((isMobile && isEmpty(activeDm) && !openContentBox) || !isMobile) && (
                        <>
                            {!dm && <>
                                <Sidebar position="left" className={isMobile ? 'use-full-width' : ''}>
                                    <Box className="chat-list-header" sx={{
                                        padding: '1rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                        {!showSearch && (
                                            <Typography variant='h6'>Chats</Typography>
                                        )}
                                        {showSearch && (
                                            <form style={{ width: '100%' }} action="#" onSubmit={(e) => {
                                                e.preventDefault();
                                                refetch();
                                            }}>
                                                <TextField
                                                    placeholder="Enter project name"
                                                    size='small'
                                                    fullWidth
                                                    color='secondary'
                                                    variant='standard'
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    sx={{ width: '100%', borderRadius: '1rem', my: 2 }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton size='small'>
                                                                    <CiSearch />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </form>
                                        )}
                                        {showSearch && <>
                                            <IconButton onClick={() => {
                                                setShowSearch(false)
                                                setSearch('')
                                            }}>
                                                <IoCloseOutline />
                                            </IconButton>
                                        </>}
                                        {!showSearch && (
                                            <IconButton onClick={() => setShowSearch(true)}>
                                                <CiSearch />
                                            </IconButton>
                                        )}
                                    </Box>
                                    {dms &&
                                        <ConversationList
                                            loading={isLoadingDms}
                                            loadingMore={isFetchingNextPageDms}
                                            onYReachEnd={handleScrollToEnd}
                                            style={{
                                                height: "100%",
                                            }}
                                        >
                                            {dms && dms.length > 0 && (
                                                dms
                                                    .filter(dm => !isEmpty(dm) && dm.hasOwnProperty('title') && !isEmpty(dm.title))
                                                    .map((data, index) => {
                                                        if (!data) return null;
                                                        return (
                                                            <Conversation
                                                                key={index}
                                                                info={renderStatus(data)}
                                                                name={data.title || `Project ${index + 1}`}
                                                                onClick={() => setActiveDm(data)}
                                                            >
                                                                <Avatar
                                                                    name={data.title || `Project ${index + 1}`}
                                                                    src="https://via.placeholder.com/35x35"
                                                                />
                                                            </Conversation>
                                                        );
                                                    })
                                            )}

                                        </ConversationList>
                                    }
                                </Sidebar>
                            </>}

                        </>
                    )
                }
                {((isMobile && openContentBox) || !isMobile) && <>
                    {activeDm ? <>
                        <ChatContainer>
                            {!dm && <>
                                <ConversationHeader>
                                    <ConversationHeader.Back onClick={() => {
                                        setActiveDm(null);
                                    }} />
                                    <Avatar
                                        name="Emily"
                                        src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
                                    />
                                    <ConversationHeader.Content
                                        info="Active 1 mins ago"
                                        userName={
                                            activeDm && activeDm.title
                                                ? activeDm.title
                                                : "Username"
                                        }
                                    >
                                        <h6>
                                            {activeDm && activeDm.title
                                                ? activeDm.title
                                                : "Username"}
                                        </h6>
                                        {activeDm &&
                                            activeDm &&
                                            isTypingUser == activeDm.id ? (
                                            <TypingIndicator content="typing" />
                                        ) : (
                                            ""
                                        )}
                                    </ConversationHeader.Content>
                                    <ConversationHeader.Actions>
                                        <InfoButton key={Date.now()} />
                                    </ConversationHeader.Actions>
                                </ConversationHeader>
                            </>
                            }


                            <MessageList
                                loadingMore={isLoading}
                                autoScrollToBottom
                                scrollBehavior='auto'
                            >
                                {messagesForDm && messagesForDm.length > 0 &&
                                    messagesForDm
                                        .filter(msg => !isEmpty(msg) && msg.type)
                                        .map((msg, index) => {
                                            const key = msg.id || index;

                                            if (+msg.type === 1) {
                                                return (
                                                    <Message
                                                        key={key}
                                                        model={{
                                                            message: msg.content,
                                                            sentTime:
                                                                "createdAt" in msg && msg.createdAt
                                                                    ? new Date(msg.createdAt).toLocaleString()
                                                                    : new Date().toLocaleString(),
                                                            sender: `${msg.senderId}`,
                                                            direction: user && msg.senderId === user.id ? "outgoing" : "incoming",
                                                        }}
                                                    >
                                                        <Message.CustomContent>
                                                            <ReactLinkify
                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                    <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key}>
                                                                        {decoratedText}
                                                                    </a>
                                                                )}
                                                            >
                                                                {he.decode(msg.content)}
                                                            </ReactLinkify>
                                                        </Message.CustomContent>
                                                    </Message>
                                                );
                                            } else if (+msg.type === 2) {
                                                return (
                                                    <Message
                                                        className='file-message'
                                                        key={key}
                                                        model={{
                                                            type: 'custom',
                                                            sender: `${msg.senderId}`,
                                                            direction: user && msg.senderId === user.id ? "outgoing" : "incoming",
                                                        }}
                                                    >
                                                        <Message.CustomContent>
                                                            <FileMessage message={msg} />
                                                        </Message.CustomContent>
                                                    </Message>
                                                );
                                            } else {
                                                return (
                                                    <Message
                                                        key={key}
                                                        model={{
                                                            message: 'Invalid message type',
                                                            type: 'text'
                                                        }}
                                                    />
                                                );
                                            }
                                        })
                                }
                                {/* <Message
                                type='custom'
                                key={Date.now()}
                                model={{
                                    message: 'testing',
                                    type: 'custom'
                                }}

                                children={
                                    <Message.CustomContent
                                        key={Date.now()}
                                    >
                                        <Button>Hey</Button>
                                    </Message.CustomContent>
                                }
                            /> */}

                            </MessageList>

                            <Box className='message-input-box' as={MessageInput}
                                sx={{ display: "flex", flexDirection: "row", borderTop: "1px dashed #d1dbe4", backgroundColor: "#fff", width: '100%' }}
                            >
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={uploadFile}
                                />

                                {/* <Paper
                                sx={{
                                    width: '84%',
                                    margin: '0 auto',
                                    height: '300px'
                                }}
                            >HEy</Paper> */}
                                <MessageInput
                                    className='text-input'
                                    attachButton
                                    sendButton={false}
                                    onSend={handleSendMessage}
                                    onChange={(message) => {
                                        handleUserTyping(message);
                                    }}
                                    onAttachClick={initiateFileUpload}
                                    placeholder="Type message here"
                                    fancyScroll
                                    sendDisabled={false}

                                />
                                <SendButton onClick={() => handleSendMessage(textInputValue)} disabled={textInputValue.length === 0} style={{ fontSize: "1.2em", marginLeft: 0, paddingLeft: "0.2em", paddingRight: "0.2em" }} />
                            </Box>
                        </ChatContainer>
                    </>
                        : (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flex: "1",
                                }}
                            >
                                Select a chat to continue
                            </Box>
                        )}
                </>
                }
            </MainContainer >
        </Box>
    )
}

export default ChatComponent