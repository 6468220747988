import { create } from "zustand";
import { persist } from "zustand/middleware";
import { API_URL, IndexDbStoreName, IndexedDbName } from "./const";
import { makeRequest } from "../util/AxiosInstance";
import { Block, Notify } from "notiflix";
import { IndexedDBUtility } from "../util/IndexedDb";
import { handleError } from "../util/functions";

// Define a store to manage projects
const useProjectStore = create((set) => ({
  ongoing: [],
  completed: [],
  totalOngoing: 0,
  totalCompleted: 0,
  ongoingPage: 1,
  completedPage: 1,
  fetchProjects: async (type, page) => {
    /**
     * Ongoing: 1
     * Pending: 2
     * Completed: 3
     */
    try {
      makeRequest(`GET`, `${API_URL}/projects?status=${type}&page=${page}&user_id=${userStore.getState().user.id}`)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("Failed to fetch projects");
          }
          const projectsData = response.data.data;
          if (projectsData.hasOwnProperty('data')) {
            var projects = projectsData.data;
            switch (type) {
              case 1:
                // console.log(projects);
                projects = projects.filter((project) => +project.status == 1);
                // console.log(projects);
                set({ ongoing: projects });
                break;
              case 2:
                projects = projects.filter((project) => +project.status == 2);
                set({ pending: projects });
                break;
              default:
                break;
            }
            set({ projects });
          }
        }).catch((error) => {
          Notify.failure("Failed to fetch projects");
          console.error("Error fetching projects:", error);
        });
    } catch (error) {
      let err = handleError(error);
      Notify.failure(err);
      console.error("Error fetching projects:", error);
    }
  },

  addProject: (newProject) => {
    set((state) => ({
      projects: [...state.projects, newProject],
    }));
  },

  removeProject: (projectId) => {
    set((state) => ({
      projects: state.projects.filter((project) => project.id !== projectId),
    }));
  },
}));

const userStore = create(persist((set) => ({
  user: null,
  loginUser: null,
  token: "",
  isLoading: false,
  isLoggedIn: () => {
    return !!localStorage.getItem("token");
  },
  setUser: (user) => {
    set({ user });
  },
  setLoginUser: (user) => {
    set({ user });
  },
  setToken: (token) => {
    set({ token });
  },
  getUser: async () => {
    set({ isLoading: true });
    makeRequest('GET', `${API_URL}/get-user`).then((response) => {
      console.log(response);
      set({ user: response.data.data });
    }).catch((error) => {
      Notify.failure("Failed to fetch user data");
      console.log(error);
    }).finally(() => {
      set({ isLoading: false });
    });
  },
  checkLogin: async () => {
    if (localStorage.getItem("token")) {
      console.log("Token exists in local storage");
      // set({ token: localStorage.getItem("token") });
      userStore.getState().getUser();
    }
  },
  logout: async () => {
    Block.circle('#root')
    let db = new IndexedDBUtility(IndexedDbName, IndexDbStoreName);
    await db.openDatabase();
    await db.deleteFromDatabase("jwt");
    set({ token: "", user: null });
    window.location.reload()
  }
}),
  {
    name: "user-storage",
    getStorage: () => localStorage,
  }));

const useMeetingStore = create((set) => ({
  meetings: [],
  currentPage: 1,
  hasMore: true,
  isLoading: false,
  fetchMeetings: async (page) => {
    try {
      let userId = userStore.getState().user.id;
      set({ isLoading: true });
      makeRequest('GET', `/user-bookings/${userId}?page=${page}`).then((response) => {
        const data = response.data.data;
        set((state) => ({
          meetings: [...state.meetings, ...data],
          hasMore: response.data.next_page_url !== null,
        }));
      }).catch((error) => {
        console.error("Failed to fetch meetings", error);
      }).finally(() => {
        set({ isLoading: false });
      });
    } catch (error) {
      set({ isLoading: false });
      console.error("Failed to fetch meetings", error);
    }
  },
  loadMore: () => set((state) => ({ currentPage: state.currentPage + 1 })),
  setCurrentPage: (page) => set({ currentPage: page }),
}));

const navigationContext = create(persist((set) => ({
  ongoing: {
    page: 1,
  },
  completed: {
    page: 1,
  },
  pending: {
    page: 1,
  },
  setOngoingPage: (page) => set({ ongoing: { page } }),
  setCompletedPage: (page) => set({ completed: { page } }),
  setPendingPage: (page) => set({ pending: { page } }),
}), {
  name: "navigation-storage",
}))


const usePortfolioStore = create((set) => ({
  portfolios: [],
  setPortfolios: (portfolios) => set({ portfolios }),
  addPortfolio: (portfolio) => set((state) => ({ portfolios: [...state.portfolios, ...portfolio] })),
  currentPage: 1,
  totalItems: 0,
  totalPages: 0,
  hasMore: true,
  setCurrentPage: (page) => set({ currentPage: page }),
  increasePage: () => set((state) => ({ currentPage: state.currentPage + 1 })),
  setTotalItems: (totalItems) => set({ totalItems }),
  setTotalPages: (totalPages) => set({ totalPages }),
  setHasMore: (hasMore) => set({ hasMore }),
}))

const useProjectStatStore = create((set) => ({
  stats: {},
  setStats: (stats) => set({ stats }),
  fetchStats: async () => {
    makeRequest('GET', `${API_URL}/projects/stat`)
      .then((response) => {
        let dd = response.data.data
        set({ stats: dd });
      }).catch((error) => {
        console.error("Failed to fetch project status", error);
      });
  }
}))


export { useProjectStore, useMeetingStore, userStore, navigationContext, usePortfolioStore, useProjectStatStore };

