import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as PlaceholderIcon } from '../assets/no_data.svg';

function Placeholder({ icon: Icon, text }) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '300px',
            minWidth: {
                xs: '100%',
                sm: '100%',
                md: '100px',
            }
        }}>
            {Icon ? <Icon style={{ width: '150px', height: '150px' }} /> : <><PlaceholderIcon style={{ width: '150px', height: '150px' }} /></>}
            <Typography variant="body2" sx={{ mt: 2 }}>
                {text}
            </Typography>
        </Box>
    );
}

export default Placeholder;