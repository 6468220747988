import React from "react";

function ProcessBlurb({ text, icon }) {
  return (
    <div className="process-item">
      <div className="inner">
        {icon}
        <span>{text}</span>
      </div>
    </div>
  );
}

export default ProcessBlurb;
