import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { makeRequest } from '../../util/AxiosInstance';
import { TitlePlusText } from '../../shared/TitlePlusText';
import { formatCurrency, formatDateFromApi } from '../../util/functions';
import { useTheme } from '@emotion/react';


const ProjectDetails = ({ project }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [orders, setOrders] = useState([]);

    const getOrders = async () => {
        await makeRequest('GET', `/get-project-orders/${project.id}`).then(res => {
            console.log(res.data.data.data);
            let data = res.data.data.data ?? [];
            setOrders(data);
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        getOrders();
    }, []);

    return (
        <Box p={{
            xs: 1,
            sm: 2,
        }} style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            textTransform: "capitalize"
        }}>
            <TitlePlusText title="Description" text={project.description} />
            <TitlePlusText title="Status" text={project.status === "1" ? "Active" : "Inactive"} />
            <TitlePlusText title="Started At" text={formatDateFromApi(project.created_at)} />
            <TitlePlusText title="End Date" text={formatDateFromApi(project.project_detail.end_at)} />
            <TitlePlusText title="Last Updated" text={formatDateFromApi(project.project_detail.updated_at)} />
            <Typography variant="h6" mb={-2}>
                Orders
            </Typography>
            <Box>
                <table className='use-table' style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>Amount</th>
                            <th>Status</th>
                            {!isMobile &&
                                <>
                                    <th>Created At</th>
                                    <th></th>
                                </>
                            }
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.length > 0 ? orders.map((order, index) => {
                            const amount = parseFloat(order.amount);
                            const status = order.status === "1" ? "Paid" : "Unpaid"; // Convert status to readable format
                            const amountError = isNaN(amount) || amount <= 0 ? 'Invalid amount' : '';

                            return (
                                <tr key={index + 1}>
                                    <td>
                                        {amountError ? <span style={{ color: 'red' }}>{amountError}</span> : formatCurrency(amount)}
                                    </td>
                                    <td>{status}</td>
                                    {!isMobile && <td>{formatDateFromApi(order.created_at)}</td>}
                                    <td>
                                        <Button variant='text' color='secondary'>
                                            Pay
                                        </Button>
                                    </td>
                                </tr>
                            );
                        }) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center' }}>
                                    <Typography>No orders available</Typography>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Box>

        </Box>
    );
};

export default ProjectDetails;