import { Typography, Box, useMediaQuery, useTheme, Button, Menu, MenuItem, ListItemIcon } from '@mui/material'
import React, { useState } from 'react'
import { userStore } from '../../zustand';
import { CgProfile } from "react-icons/cg";
import { isEmpty } from 'underscore';
import { IoIosLogOut } from "react-icons/io";
function ProfileTab() {
    const theme = useTheme();
    const { user } = userStore()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { logout } = userStore()

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Add your logout logic here
        logout();
        handleClose();
    };

    return (
        <Box
            sx={{
                ml: "auto",
                alignItems: "center",
                display: "flex",
                gap: "10px",
            }}
        >
            <Button
                variant='text'
                startIcon={<CgProfile style={{ color: '#444' }} />}
                onClick={handleClick}
            >
                {!isMobile &&
                    <Typography
                        textTransform={'capitalize'}
                        variant="body1"
                        fontWeight={"500"}
                        fontSize={'1.1rem'}>
                        {!isEmpty(user) && user.hasOwnProperty('first_name') ? `${user.first_name} ${user.last_name}` : 'Guest'}
                    </Typography>
                }
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    marginTop: '20px',
                    marginLeft: '10px'
                }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <IoIosLogOut style={{ color: '#444' }} />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default ProfileTab