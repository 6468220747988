import { Image } from '@mui/icons-material'
import { Card, CardContent, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { motion } from 'framer-motion'
import React from 'react'

function PortfolioItem({ portfolio, setViewerId, setShowViewer }) {
    return (
        <Card
            onClick={() => {
                setViewerId(portfolio.id);
                setShowViewer(true);
            }}
            sx={{
                maxWidth: {
                    xs: '100%',
                    sm: '100%',
                },
                borderRadius: '1rem',
                transition: 'transform 0.2s',
                '&:hover': {
                    transform: 'scale(1.01)',
                }
            }} component={motion.div}>
            <Box>
                <img style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                }} src={portfolio.image ?? 'https://via.placeholder.com/600x400'} alt={portfolio.name} />
            </Box>
            <CardContent>
                <Typography textTransform={'capitalize'} variant='h6'>
                    {portfolio.name}
                </Typography>
                <Typography textTransform={'capitalize'} variant='body2' mt={1}>
                    {portfolio.category}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default PortfolioItem