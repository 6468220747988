export class IndexedDBUtility {
  constructor(databaseName, storeName) {
    this.databaseName = databaseName;
    this.storeName = storeName;
    this.db = null;
  }

  openDatabase() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(this.databaseName);

      request.onerror = function (event) {
        reject("Error opening database");
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        resolve(this.db);
      };

      request.onupgradeneeded = (event) => {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains(this.storeName)) {
          this.db.createObjectStore(this.storeName, { keyPath: "id" });
        }
      };
    });
  }

  writeToDatabase(data) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readwrite");
      const objectStore = transaction.objectStore(this.storeName);

      const addRequest = objectStore.put(data);

      addRequest.onsuccess = function (event) {
        resolve("Data added successfully");
      };

      transaction.oncomplete = function (event) {
        resolve("Transaction completed");
      };

      transaction.onerror = function (event) {
        console.error(event);
        reject("Transaction error");
      };
    });
  }

  readFromDatabase(id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readonly");
      const objectStore = transaction.objectStore(this.storeName);

      const getRequest = objectStore.get(id);

      getRequest.onsuccess = function (event) {
        const data = event.target.result;
        resolve(data);
      };

      getRequest.onerror = function (event) {
        reject("Error reading data");
      };
    });
  }

  updateInDatabase(id, newData) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readwrite");
      const objectStore = transaction.objectStore(this.storeName);

      const getRequest = objectStore.get(id);

      getRequest.onsuccess = function (event) {
        const existingData = event.target.result;

        if (existingData) {
          const updatedData = { ...existingData, ...newData };
          const updateRequest = objectStore.put(updatedData);

          updateRequest.onsuccess = function (event) {
            resolve("Data updated successfully");
          };

          updateRequest.onerror = function (event) {
            reject("Error updating data");
          };
        } else {
          reject("Data not found");
        }
      };

      getRequest.onerror = function (event) {
        reject("Error reading data");
      };
    });
  }

  deleteFromDatabase(id) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(this.storeName, "readwrite");
      const objectStore = transaction.objectStore(this.storeName);

      const deleteRequest = objectStore.delete(id);

      deleteRequest.onsuccess = function (event) {
        resolve("Data deleted successfully");
      };

      deleteRequest.onerror = function (event) {
        reject("Error deleting data");
      };
    });
  }
}