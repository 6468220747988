import React from 'react';
import { Container, Typography, Box, Link, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container className='plain-page-container' maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Doshby's Commitment to Privacy
        </Typography>

        <Typography paragraph>
          At Doshby, we are dedicated to providing cutting-edge software solutions while upholding the highest standards of privacy and data protection. This Privacy Policy outlines how we collect, use, and safeguard the personal information of our valued clients and website visitors. Maintaining your trust is of utmost importance to us.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography paragraph>
          In order to provide our services effectively, we may collect certain personal information from you, such as:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Contact Details: Name, email address, phone number." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Technical Information: IP address, browser type, device information, and cookies (for website analytics and improving user experience)." />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use the collected information solely for the purposes of:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Delivering and enhancing our software development services to meet your needs." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Communicating with you about project updates, new offerings, and relevant information." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Analyzing usage trends to continuously improve our solutions and user experience." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Complying with legal obligations and industry regulations." />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Protecting Your Privacy
        </Typography>
        <Typography paragraph>
          We implement robust security measures, both technical and organizational, to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. Our team adheres to strict confidentiality protocols, and we work only with trusted third-party service providers who are contractually obligated to maintain the same high levels of data protection.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Your Rights and Choices
        </Typography>
        <Typography paragraph>
          Doshby respects your privacy rights and choices. You have the option to access, update, or request the deletion of your personal information at any time. If you have any questions or concerns about how we handle your data, please don't hesitate to contact us.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Transparency and Updates
        </Typography>
        <Typography paragraph>
          We are committed to being transparent about our data practices. Should we make any updates to this Privacy Policy, we will notify you promptly and post the new version on our website.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions, concerns, or feedback regarding our Privacy Policy or data handling practices, please reach out to our dedicated support team at <Link href="https://doshby.com">doshby.com</Link>. We are here to address your inquiries and ensure your utmost satisfaction with Doshby's services and commitment to privacy.
        </Typography>

        <Typography paragraph>
          At Doshby, we believe that privacy and security are fundamental to building trust with our clients. By prioritizing transparency, implementing robust safeguards, and respecting your rights, we aim to provide you with the peace of mind you deserve as we collaborate on bringing your digital visions to life.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;