import React from "react";
import { Typography, Button, Container, Box, Link } from "@mui/material";
import {Error as ErrorIcon} from "@mui/icons-material";
import { PRIMARY_COLOR } from "../../zustand/const";

const ErrorPage = () => {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={10}
        mb={4}
      >
        <ErrorIcon sx={{ fontSize: "100px", color: PRIMARY_COLOR }} />
        <Typography variant="h4" color="primary" mt={2} mb={3}>
          Oops! Something went wrong
        </Typography>
        <Typography variant="body1" align="center">
          We're sorry, but it seems there was an error while processing your
          request. Please try again later.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" component={Link} href="/">
          Go Home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
