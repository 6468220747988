import axios from "axios";
import { API_URL, IndexDbStoreName, IndexedDbName } from "../zustand/const";
import { IndexedDBUtility } from "./IndexedDb";
import { isEmpty } from "underscore";
//
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let db = new IndexedDBUtility(IndexedDbName, IndexDbStoreName);
    await db.openDatabase();
    const token = await db.readFromDatabase('jwt');
    if (!isEmpty(token)) {
      const { jwt } = token;
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const makeRequest = (method, url, data, headers) => {
  if (isEmpty(headers)) {
    headers = {
      "Content-Type": "application/json",
    };
  }
  return axiosInstance({
    method: method,
    url: url,
    data: data,
    headers: headers,
  });
};
export default axiosInstance;