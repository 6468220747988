import React, { useEffect } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Button } from '@mui/material';
import { motion } from 'framer-motion';
import CodeIcon from '@mui/icons-material/Code';
import DevicesIcon from '@mui/icons-material/Devices';
import homeimg2 from '../../assets/homeimg2.jpg';
import LayersIcon from '@mui/icons-material/Layers';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6 } }
  };

  const slideIn = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.6 } }
  };

  useEffect(() => {
    // const e = document.querySelector('.use-underline');
    // const annotation = annotate(e, { type: 'underline' });
    // annotation.show();
  }, [])

  return (
    <Box>
      {/* Hero Section */}
      <Box
        component={motion.div}
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        sx={{
          py: 15,
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'white',
          minHeight: '300px',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Typography fontWeight={400} variant="h3" component="h1" gutterBottom>
            About Doshby
          </Typography>
          <Typography fontWeight={300} variant="body1" gutterBottom>
            Bringing Digital Visions to Life
          </Typography>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ my: 12 }}>
        <Grid container spacing={6} my={16}>
          <Grid component={motion.div}
            display={"flex"}
            flexDirection={"column"}
            gap={"1rem"}
            justifyContent={'center'}
            initial="hidden"
            animate="visible"
            variants={slideIn}
            item xs={12} md={6}
            sx={{
              textAlign: {
                xs: 'center',
                md: 'left'
              },
              alignItems: {
                xs: 'center',
                md: 'flex-start'
              }
            }}>
            <Typography variant="h4" gutterBottom>
              Mastering the Art of Software Development
            </Typography>
            <Typography variant="body1" paragraph>
              At Doshby, we are a cutting-edge software development agency that specializes in crafting innovative and scalable solutions for businesses of all sizes. Our team of dedicated software engineers possesses a wealth of expertise across a diverse range of technologies, empowering us to tackle projects of any complexity with precision and excellence.
            </Typography>
            <Button LinkComponent={Link} to='http://wa.link/blzxa3' variant="contained" color="secondary">
              Learn More
            </Button>
          </Grid>
          <Grid
            component={motion.div}
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            item xs={12} md={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              marginTop: {
                xs: '2rem',
                md: 0
              }
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', maxHeight: '500px', maxWidth: '350px', alignItems: 'center' }}
              className="curve-container"
            >
              <img
                src={homeimg2}
                alt="Doshby Team"
                style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
              />
            </Box>
          </Grid>
        </Grid>

        {/* Services Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom align="center">
            Our Services
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {[
              { icon: <CodeIcon fontSize="large" />, title: "Front-End Development", description: "We create captivating and intuitive user interfaces that enhance user engagement and deliver exceptional digital experiences.", color: "#875C00" },
              { icon: <LayersIcon fontSize="large" />, title: "Back-End Development", description: "Our robust and high-performance back-end systems ensure seamless functionality, scalability, and reliability for your applications.", color: "#000687" },
              { icon: <DevicesIcon fontSize="large" />, title: "Full-Stack Development", description: "Combining our front-end and back-end proficiencies, we develop complete, end-to-end web and mobile solutions that meet the highest industry standards.", color: "#8086FF" }
            ].map((service, index) => (
              <Grid
                component={motion.div}
                initial="hidden"
                animate="visible"
                variants={fadeIn}
                item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%', display: 'flex', flexDirection: "column", justifyContent: 'center', boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
                    backgroundColor: service.color, color: 'white', borderRadius: '1rem'
                  }}>
                  <CardContent sx={{
                    display: 'flex', flexDirection: "column", justifyContent: 'center', minHeight: '300px', gap: '1rem',
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center", mb: 2 }}>
                      {service.icon}
                    </Box>
                    <Typography variant="h6" align="center" gutterBottom>
                      {service.title}
                    </Typography>
                    <Typography variant="body2" align="center">
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Commitment Section */}
        <Box
          component={motion.div}
          initial="hidden"
          animate="visible"
          variants={slideIn}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left'
            },
            mt: 16
          }}>

          <Typography
            sx={{
              maxWidth: '400px',
              marginBottom: '2rem'
            }}
            className='use-underline'
            variant="h4" gutterBottom>
            Committed to Your Success
          </Typography>
          <Typography variant="body1" paragraph mt={3}>
            At Doshby, we are driven by a passion for innovation and a commitment to delivering outstanding results. Whether you're a startup seeking to launch a groundbreaking product or an established enterprise looking to optimize your digital operations, our team collaborates closely with you to understand your unique goals and challenges.
          </Typography>
          <Typography variant="body1" paragraph>
            We pride ourselves on our agile approach, fostering open communication throughout the development lifecycle to ensure your vision is brought to life precisely as you envision it. Our meticulous attention to detail, adherence to best practices, and dedication to timely delivery make us the trusted partner for businesses seeking to stay ahead of the curve.
          </Typography>
        </Box>

        {/* Call to Action */}
        <Box
          className='cta'
          sx={{
            mt: 16, textAlign: 'center', backgroundColor: "white", borderRadius: '1rem', padding: {
              xs: '2rem',
              sm: '6rem'
            },
          }}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeIn}
            style={{
              display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center"
            }}
          >
            <Typography variant="h4" gutterBottom>
              Join the Doshby Experience
            </Typography>
            <Typography variant="body1" paragraph>
              Unlock the full potential of your digital ambitions with Doshby. Contact us today to schedule a consultation and learn how our cutting-edge software solutions can propel your business to new heights.
            </Typography>
            <Button component={Link} to="/schedule" variant="contained" color="primary" size="large">
              Book Now
            </Button>
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;