import { Button, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import BillingTable from '../components/BillingTable'
import { BillingOrderStatus } from '../../zustand/const'

function Billing() {
  return (
    <div>
      <Typography fontWeight={600}
        variant="h5">Billing</Typography>
      <Box sx={{
        my: '2rem'
      }}>
        <BillingTable status={BillingOrderStatus.PENDING} children={<> <Typography fontWeight={600} mb='0.5rem' variant="body1">Pending Orders</Typography>
          <Typography variant="subtitle">Manage billing information and view receipts</Typography></>} />
      </Box>
      <Box sx={{
        mt: '4rem'
      }}>
        <BillingTable
          status={BillingOrderStatus.SUCCESS}
          children={<>
            <Typography fontWeight={600} mb='0.5rem' variant="body1">Order History</Typography>
            <Typography variant="subtitle">Manage billing information and view receipts</Typography></>} />
      </Box >
    </div>
  )
}

export default Billing