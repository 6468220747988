import { Box, Grid, Avatar, Typography, Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-wrapper">
      <Box className="footer">
        <Grid className="inner" justifyContent="space-between" container spacing={3}>
          <Grid padding={0} sx={{
            padding: {
              xs: 0
            }
          }} item xs={12} sm={6} md={3}>
            <Typography variant="body1" className="footer-menu-title">
              Site
            </Typography>
            <div className="footer-menu-items">
              <Link component={RouterLink} to="/">
                Home
              </Link>
              <Link component={RouterLink} to="/about">
                About
              </Link>
              <Link component={RouterLink} to="/faq">
                FAQ
              </Link>
            </div>
          </Grid>
          <Grid item

            xs={12} sm={6} md={3}>
            <Typography variant="body1" className="footer-menu-title">
              Support
            </Typography>
            <div className="footer-menu-items">
              {/* <Link component={RouterLink} to="https://doshby.com/help">
                Help center
              </Link> */}
              <Link component={RouterLink} to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link component={RouterLink} to="/terms-of-service">
                Terms Of Service
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="body1" className="footer-menu-title">
              Contact
            </Typography>
            <div className="footer-menu-items">
              <Link href="mailto:support@doshby.com">support@doshby.com</Link>
              {/* <Typography>+1(091) 123 4567</Typography> */}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box
        className="copyright"
        sx={{ textAlign: "center", backgroundColor: "E8EAFC" }}
      >
        © Copyright Doshby {new Date().getFullYear()}
      </Box>
    </div>
  );
}

export default Footer;
