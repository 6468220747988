import React, { useState } from "react";
import {
    Grid,
    Typography,
} from "@mui/material";

function AuthWrapper({ left, right }) {

    return (
        <>
            <Grid
                container
                className="authContainer"
                sx={{
                    alignItems: "center", justifyContent: "center", maxWidth: {
                        md: '80%',
                        lg: '100%'
                    },
                    margin: {
                        md: 'auto'
                    }
                }}
            >
                <Grid item xs={12} lg={6} className="left">
                    <a href="/">
                        <Typography variant="h5" align="left">
                            Doshby
                        </Typography>
                    </a>
                    <div className="form-wrapper">
                        {left}
                    </div>
                    <div className="copyright">&copy; 2024 Doshby.</div>
                </Grid>
                <Grid item xs={0} lg={6} className="right image-div">
                    <div
                        style={{
                            width: "80%",
                            height: "80%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {right}
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default AuthWrapper;
