import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import BookingCard from '../components/BookingCard';

const bookingsData = {
  status: true,
  message: "All bookings retrieved successfully.",
  data: {
    current_page: 1,
    data: [
      {
        id: 1,
        title: "new booking",
        description: "lets test the booking",
        start_at: "2024-06-08 09:43:00",
        end_at: "2024-06-08 09:43:00",
        user_id: "22",
        status: "0",
        created_at: "2024-06-08T16:33:02.000000Z",
        updated_at: "2024-06-08T16:33:02.000000Z"
      },
      {
        id: 2,
        title: "new booking",
        description: "testing booking",
        start_at: "2024-06-08 09:43:00",
        end_at: "2024-06-08 09:43:00",
        user_id: "22",
        status: "0",
        created_at: "2024-06-08T16:48:22.000000Z",
        updated_at: "2024-06-08T16:48:22.000000Z"
      }
    ],
    first_page_url: "http://127.0.0.1:8000/api/bookings?page=1",
    from: 1,
    last_page: 1,
    last_page_url: "http://127.0.0.1:8000/api/bookings?page=1",
    links: [
      {
        url: null,
        label: "&laquo; Previous",
        active: false
      },
      {
        url: "http://127.0.0.1:8000/api/bookings?page=1",
        label: "1",
        active: true
      },
      {
        url: null,
        label: "Next &raquo;",
        active: false
      }
    ],
    next_page_url: null,
    path: "http://127.0.0.1:8000/api/bookings",
    per_page: 10,
    prev_page_url: null,
    to: 2,
    total: 2
  },
  errors: null
};

function Meetings() {
  return (
    <>
      <Box p={2}>
        <Typography variant="h4" gutterBottom>
          Bookings
        </Typography>
        <Grid container spacing={2}>

          {bookingsData.status ? (
            bookingsData.data.data.map((booking) => (
              <Grid item xs={12} md={6} lg={4}>
                <BookingCard key={booking.id} booking={booking} />
              </Grid>
            ))
          ) : <></>}
        </Grid>

      </Box>
    </>


  )
}

export default Meetings
